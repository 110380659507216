/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out fresh */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~jqwidgets-ng/jqwidgets/styles/jqx.energyblue.css";
@import "~jqwidgets-ng/jqwidgets/styles/jqx.shinyblack.css";
@import "~jqwidgets-ng/jqwidgets/styles/jqx.material-purple.css";
@import "./assets/Jqx_styles/jqx.treeiot.css";
@import "./assets/Jqx_styles/jqx.iotify.css";
@import "./assets/Jqx_styles/jqx.emsiotify.css";
*{
    font-family: 'Trebuchet MS','Verdana','sans-serif';
    font-size: 0.875rem;
    // font-variant: small-caps;
    //color:var(--iot-fontColor);
};
ion-item[class*="default"]{
    color:var(--iot-fontColor);
};
// ion-button{
//     --color:var(--iot-fontColor);
// };
// ion-segment-button{
//     &::part(native){
//     text-transform: capitalize !important;
//     font-variant: small-caps !important;
//     }
//       --color:var(--iot-fontColor);
// };
ion-button,ion-segment-button{
    &::part(native){
    text-transform: capitalize !important;
    font-variant: small-caps !important;
    }
 
    --color:var(--iot-fontColor);
};

.btnNormalStyle   {
    &::part(native){
        text-transform: none !important;
        font-variant: none !important;
        }
}
// ion-content{
//     &::part(background){
//         // background:url('../../assets/img/grid/remon_in_background.jpg')center center / cover no-repeat fixed;
//         // background:url('../../assets/img/grid/testbackgroud.jpg')center center / cover no-repeat fixed;
//         background: linear-gradient(180deg,var(--iot-backgroundPrimary),var(--iot-backgroundSecondary),var(--iot-backgroundTertiary))!important;
//     }
// }
// input[type="date"]::-webkit-calendar-picker-indicator {
    //display: none;
    // -webkit-appearance: inverse(1);
    // color: white;

    // width: 15px;
    // padding: 0px;
    // margin: 0px;
    // margin-top: 10px;
// }
.dashboardGrid .jqx-dropdownlist-state-normal-iotify{
    height: 23px !important;
    margin-top: 2.5px !important;
    line-height: 1;
}
.dashboardGrid .jqx-grid-pager-input-iotify{
    height: 23.5px !important;
    margin-top: 2.5px !important;
}
 
.iot-font-primary{
    font-family: 'Trebuchet MS','Verdana','sans-serif' !important;
    font-size: 13px;
    font-variant: small-caps;
}
.iot-font-secondary{
    font-family: 'Trebuchet MS','Verdana','sans-serif' !important;
    font-size: 13px;
  
}



.card-content-md {
    padding: 0px;
}
.ng-disabled{
    cursor: not-allowed;
}

ion-col {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    // padding-left: 0px;
}

.side-filter-custom-class {
    height: 90% !important;
}

.ion-input-type-number {
    border: 1px solid #bcbcbc;
}

.close-modal {
    display: flex;
    align-items: flex-end;
}

.level-card-padding {
    padding: 0 4px;
  }

.loading-gif {
// height: 1rem;
// width: 1.5rem;
margin: 5px 10px 0px 0px;
}

.spin {
  --spinner-color: rgb(109, 109, 209);
 
}
//!iotify themes===================
.custom-iot-primary{
    background-image: linear-gradient(180deg, #1E3B70,#29539B);
    color:white

};
.iot-innerContent{
    background-color: var( --iot-innerContentBg) !important;
}

//!iotify themes===================

.my-custom-class{
    &::part(content){
        background-color:#fdfbfbf5;
        width: 70%;
        height:38%;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
  
    // --border-radius: 20px;
    // --max-height:fit-content;
    // --max-width: 70%;
   
    // --border-style: dotted;
    // --border-color: #428cff;
    // border-width: 5px;
    

//   border: 1px solid rgb(14 30 37 / 32%);"
   
}
// .my-custom-class.stack-modal {
//     --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
//     --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
//   }
.my-custom2-class{
    &::part(content){
        background-color:#fdfbfbf5;
        width: 70%;
        height:38%;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}
.remotecommandmodel{
    &::part(content){
        background-color:#fdfbfbf5;
        width: 40%;
        height:50%;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}
.simDeviceModal{
    &::part(content){
        background-color:#fdfbfbf5;
        width:450px;
        height:50%;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}
.insideModal{
    &::part(content){
        background-color:#fdfbfbf5;
        width:50%;
        top:20%;
        height:55%;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}
.siteInsideModal{
   
&::part(content){
        background-color:#fdfbfbf5;
        width:30%;
        height:30%;
        border-radius: 12px;
        top:60%;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }    
   
}
.jqx-gauge-caption{
    fill:#333333;
    color:#333333;
    font-size: 10px;
    font-family: sans-serif !important;
    font-variant: small-caps;
    font-weight: bold;

}
.jqx-gauge-label{
    fill:#333333;
    color:#333333;
    font-size: 8px !important;
    // font-family: Verdana;
    // font-weight: bold;
}
.change-label-caption {
    ::ng-deep .jqx-gauge-label {
        font-size: 9px !important;
    }
    ::ng-deep .jqx-gauge-caption {
    font-size: 9px !important;
    }
}
.settingsPage{
    &::part(content){
        background-color:#fdfbfbf5;
        width: 100%;
        height:100%;
        //border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}
.sitePageModal_2{
    &::part(content){
        background-color:#fdfbfbf5;
        width: 80%;
        height:85%;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
};

.sitePageModal_1{
    &::part(content){
        background-color:#fdfbfbf5;
        width: 50%;
        height:55%;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}
.featureModal{
    &::part(content){
        background-color:#fdfbfbf5;
        width: 20%;
        height:50%;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}
.sitePageMap{
    &::part(content){
        background-color:#fdfbfbf5;
        width: 50%;
        height:70%;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}
.selectionPop{
    &::part(content){
        background-color:#fdfbfbf5;
        position:absolute;
        width: 50px;
        height:100px;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}
ion-modal[class*="action-modal"] {
    &::part(content){
        background-color:#fdfbfbf5;
        position: absolute !important;
        border: 1.5px solid yellow;
        top: 1% !important;
        width: 85% !important;
        height:100% !important;
        border-radius: 0px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 20px;
    top: 4px;
    left: 27px;
}

.slider {
    border: 0;
    padding: 0;
    display: block;
    // margin: 12px 5px;
    min-height: 11px;
    margin: 0 ! important;
    // position: absolute;
    cursor: pointer;
    top: 0;
    left: -2px;
    right: 0;
    bottom: 0;
    background-color: red;
    // -webkit-transition: .4s;
    transition: .4s;
    position: absolute;
}
.slider.round {
    border-radius: 34px ! important;
}

input:checked+.slider {
    margin: 0px ! important;
    background-color: #4caf50;
}
// we going to set class attribute global level
[class*="viewportHeightSt"] {
    height:90vh;
  }
//   app-dashboard{

//   }
ion-icon:hover{
cursor:pointer;
}
ion-item[class*="inputrapper"]{
    &::part(native){
        --min-height:40px;
    //    height:40px;
    }
    box-shadow: rgb(50 50 105 / 15%) 0px 2px 5px 0px, rgb(119 217 181 / 50%) 0px 1px 1px 0px;//prety
    --background-hover: #cfdde9;  
    margin: 3px;
    font-size: 0.9rem;
    color:var(--iot-fontColor);
}

//!dashboard grid line break removing using this classname
// div[title]:hover::after {
//     content: attr(title);
//     position: absolute;
//     z-index: 1000;
//     top: 100%;
//     left: 0;
//     border: 1px solid gray;
//   }

ion-input[type="date"]{
    font-size: 13px;
    font-weight: 600;
    color: #6d6d6d;
}
.dashboardcells {
    border-width: 0px 0px 1px 0px !important;
    
        // border-style: solid;
        // border-width: 0px 0px 1px 0px;
        // margin-right: 1px;
        // margin-bottom: 1px;
        // border-color: transparent;
        // background: #fff;
        // white-space: nowrap;
        // font-weight: normal;
       // font-size: 0.9rem;
        // overflow: hidden;
        // position: absolute !important;
        // height: 100%;
        // outline: none;
        div {
            font-weight: 600 !important;
        }
}

.section-div{
    border-right: 1px solid lightsteelblue;
    padding: 2px 2px 0px 0px;
}
//! 
.iot-btn-primary{
    --background: var(--iot-innerContentBg);
    color:var(--iot-primaryFontColor);
}
.com-filtercss{
    background: #2c7c79;
    /* padding: 5px; */
    border-radius: 3px;
    color: white;
    height: 35px;
  }
 .iotify-font-bold{
    font-size: 0.9rem;
    font-weight: 500;
 }
 .iotify-font{
    font-size: 0.9rem;
 }
 div[data-role="treeview"] span{
    border-radius: 50% !important;
 }
 //this line for ion toggle at remote commands...
 ion-toggle{
    --background:#e00000;
    --background-checked:#e00000;
 }
 // this rempte commands...
 //grid page seritiy column 
 .tooltipimg:hover .tooltiptext2{
    visibility: visible;
  }
 //severity 

 //ion search border 
 ion-searchbar{
    --border-radius: 25px !important;
 }
 //login page item size alt
 ion-item[class*="login-input"]{
    &::part(native){
        --min-height:38px !important;
       
    }
    // box-shadow: rgb(50 50 105 / 15%) 0px 2px 5px 0px, rgb(119 217 181 / 50%) 0px 1px 1px 0px;//prety
    // --background-hover: #cfdde9;  
    // margin: 3px;
    // font-size: 0.9rem;
};
// theme set
.themeTemp{
    // background: linear-gradient(#3ab3ae 7%, #06e5dc 80%, #2adbd4d6 0%) !important;
    // background: linear-gradient(#09203F 7%, #558db8 80%, #4f90cb 0%) !important;
    background-color: #2c7c79;
}

//jqx widgets cjhangess only
.jqx-grid-pager-input-iotify{
   text-align: center !important;
   padding: 0 !important;
   
}
//jqx widgets cjhangess only

//iotify theme applications
.headerTheme-iotify{
    font-size:1rem;
    font-weight: 500;
    color:white;
    text-transform: capitalize;
    letter-spacing: 0px;
}
.headerTheme-iotify1{
    font-variant: small-caps !important;
}
//iotify theme applications
//searchbar custom styling
.dashboardSearch .searchbar-input{
    line-height: 1.375rem;
    font-size: 12px;
    padding-inline-end: 40px;
}
.dashboardSearch .searchbar-search-icon{
    left: 12px;
    top: 8px;
    width: 1.3125rem;
    height: 1.3125rem;
}

//searchbar custom styling
//! jqx grid theme overirde IOTIFY theme

.jqx-widget-header-iotify{
   
       // background: linear-gradient(180deg, #2c7c79, #40b0ac,#329592)!important;
        // background: linear-gradient(180deg, #000000, #151515, #000000) !important;
        // background: linear-gradient(180deg, #0155d2, #0155d2, #0155d2) !important;
        // background: linear-gradient(180deg, #013888, #013888, #013888) !important;
        background: linear-gradient(180deg, #150464, #150464, #150464) !important;
        // background: linear-gradient(180deg, #24034b, #24034b, #24034b) !important;
        color:white !important;
    
    }
    .jqx-fill-state-hover-iotify{
        font-size: 13px !important; 
        //  background-color: #dcdcdc !important;
         background-color: #dad3ff !important;
    }
    .jqx-fill-state-pressed-iotify{
        font-size: 14px !important;
    // background-color: #a9a9a9 !important;
    // background-color: #b8d4fb !important; light bue
    background-color: #dad3ff !important; //violet like
    font-weight: 400 !important;
    color: black !important;
    }
        .jqx-item-iotify input[type="button"]{
            background:#4CAF50;
        }

        .remotegridtriggercol{
            height: 82%;
            width: 92%;
            display: flex;
            justify-content: space-around;
            color:whitesmoke;
            align-items: center;
            background: #4CAF50;
            /* margin-right: 0px; */
            margin-left: 6px;
            margin-top: 4px;
            /* border: 1px solid gray; */
            cursor: pointer;
            border-radius: 5px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        }

//--jqx-grid-hovered-row-background-color-iotify
//var(--jqx-grid-hovered-row-text-color-iotify) !important
//! jqx grid theme overirde    #4CAF50
.mobileDgModal{
    &::part(content){
        background-color:#fdfbfbf5;
        width: 35% !important;
        height:40% !important;
        top:20% !important;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}






//! thisf all style class for seperately to elint new layout
.iot-single-layout-main{
    &::part(background){
        // background:url('../../assets/img/grid/remon_in_background.jpg')center center / cover no-repeat fixed;
        // background:url('../../assets/img/grid/testbackgroud.jpg')center center / cover no-repeat fixed;
        background: linear-gradient(180deg,var(--iot-backgroundPrimary),var(--iot-backgroundSecondary),var(--iot-backgroundTertiary))!important;
    }
   --ion-color-warning: var(--ems-header-color)!important;
   --ion-color-warning-contrast: var(--ems-text-color)!important;
   .iot-white-BG{
    background: white ;
};
ion-searchbar{
    --ion-color-medium:var(--ems-header-color);
}
ion-label,label{
    color:white;
};

input[type='date']::-webkit-calendar-picker-indicator{
    filter:invert(1)
 }
};
ion-label,label{
    color:black;
}
// ion-content{
//     .iot-white-BG{
//         background: transparent ;
//     }
// }
.iot-reflect-mainbg{
    background: linear-gradient(180deg,var(--iot-backgroundPrimary),var(--iot-backgroundSecondary),var(--iot-backgroundTertiary))!important;
}

// .iot-single-layout-content{}
.iot-single-layout-contentF{
    font-size: 0.8rem;
    color: var(--iot-backgroundContentFont) !important;
}
.iot-single-layout-hover{
    background: var(--iot-backgroundHover) !important;
}
.iot-customclass-FH{
    height: 99%;
}
.iot-customclass-BS{
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.iot-single-layout-contentgrid{
    background: var(--iot-backgroundContentGrid) !important;
}
.iot-border-radius{
    border-radius: 8px;
    
}
.iot-padding-margin-null{
    padding: 0  !important;
    margin: 0 !important;
}
.iot-margin-bottom{
    margin-bottom: 3px;
}
.iot-margin-top{
    margin-top: 3px;
}
.iot-border-apply{
    border: 1px solid var(--iot-backgroundHover) !important ;
}
.newemstheme{
    background:var(--iot-borderTheme) !important;
    background: linear-gradient(180deg,var(--iot-borderThemeP),var(--iot-borderThemeS),var(--iot-borderThemeT))!important;
   
};
.iot-whitetext{
    font-size: 0.8rem;
    color:white !important;
  }
  //! jqx widget override css EMSIOTIFY  //d5e5e4   violet b7a7ff82

  .jqx-grid-cell-emsiotify{
    background: rgba(255, 0, 0, 0) !important;
  };
  .jqx-grid-cell-pinned-emsiotify{
    background: rgb(10 14 14 / 91%) !important;
  }
  .jqx-widget-content-emsiotify{
    background: rgba(255, 0, 0, 0) !important;
    color: white;
  }
  .jqx-widget-header-emsiotify{
   
    // background: linear-gradient(180deg, #2c7c79, #40b0ac,#329592)!important;
    //  background: linear-gradient(180deg, #000000, #050505d4, #000000) !important;
    //   background:#4e4087 !important;
    background: linear-gradient(180deg,var(--iot-borderThemeP), var(--iot-borderThemeS),var(--iot-borderThemeT)) !important;
     color:white !important;
 
 }
 .jqx-fill-state-hover-emsiotify{
     font-size: 13px !important; 
      background-color: #a4bed491 !important;
      color: white !important;
 }
 .jqx-grid-cell-emsiotify{
    color: white !important;
 }
 .jqx-fill-state-pressed-emsiotify{
     font-size: 14px !important;
 background-color: #a4bed4 !important;
 font-weight: 400 !important;
 color: rgb(245, 240, 240) !important;
 }
 .jqx-grid-cell-selected-emsiotify{
    background-color: #a4bed4 !important;
 }
     .jqx-item-iotify input[type="button"]{
         background:#4CAF50;
     }

     .remotegridtriggercol{
         height: 82%;
         width: 92%;
         display: flex;
         justify-content: space-around;
         color:whitesmoke;
         align-items: center;
         background: #4CAF50;
         /* margin-right: 0px; */
         margin-left: 6px;
         margin-top: 4px;
         /* border: 1px solid gray; */
         cursor: pointer;
         border-radius: 5px;
         box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
     }

     input[type="textarea"].jqx-widget-content-emsiotify{
        font-size: 14px;
        resize: none;
        background: var(--jqx-editors-text-editors-background-color-emsiotify);
        color: var(--jqx-editors-text-editors-text-color-emsiotify);
        border: none;
        border-radius: 0;
        box-sizing: border-box;
        box-shadow: none;
       background-color:#666666db  !important;
        border-bottom: 1px solid var(--jqx-editors-text-editors-border-color-emsiotify);
     }

     //! only for combobox
     .jqx-input-label{
        opacity: 0 !important;
    }
     //! only for combobox
  //! jqx widget override css EMSIOTIFY
  iot-white_header{
    font-size: 1.2rem;
    color: white;
  }
  ion-item[color*="transperant"]{
    color: white;
  }


  .black-font{
    color:black;
}
.iot-flex-style{
display: flex;
justify-content: center;
align-items: center;
}
.iot-select-tag-style{
    background-color: #000000 !important;
    border-radius: 5px !important;
    outline: none;
};
.iot-sticy-ontop{
    position: sticky;
    top: 1px;
    z-index: 50;
}
.alertselect{

    width: 63%;
    border: 1px solid #a4bed4;
    border-radius: 3px;
    color:black;
    outline: none;
  }
// ion-item[color='transparent']{
//     ion-text{
//         color:white;
//     }
// }
ion-item[color='transparent']{
    ion-text{
        color:white;
    }
    ion-input{
        color:white;
    }
    .lblColor{
        color:white;
    }
}
//!highcharts theme
.ems-highchartsSet{
    text{
        fill: var(--iot-fontColor) !important;
    }
};
.iot-highchartCotainer{
    height:100%;
}

//!highcharts theme

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
}

//component or element styling..
.iot-ems-applicationLogo{
    height: 7%;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        height: 80%;
        width: 70%;
    }
};

ion-searchbar[class*="locationonly"] .searchbar-input-container{
//site add modal location
    input{
        line-height: 18px;
    }
    ion-icon{
      top:6px !important;
    }
}
//component or element styling..
ion-segment-button {
    height: 30px;
  min-height: 30px;
  text-transform: capitalize;
  }
  .iot-fullHeight{
    height: 100%;
  };
//   .tempBorder{
//     border: 1px solid red;
//   }
//   .tempBorderY{
//     border: 1px solid rgb(157, 145, 12);
//   }
//   .tempBorderG{
//     border: 1px solid rgb(24, 221, 86);
//   }


//temprory fixing
.jqxtree-iot [id $="horizontalScrollBar"]{
    visibility: collapse !important;
}

.button-value {
    // border-bottom: 2px solid var(--ion-color-primary);
    height: 27px;
    font-size: 13px !important;
    font-weight: bold !important;
    font-variant: small-caps;
    background-color: #fff;
    // border-bottom: 3px solid #2196F3;
    color: #555 !important;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom: 3.5px solid #599fda;
    /* border-bottom: 2px solid black; */
    // background: #110f0f3d;
    /* border-radius: 5px; */
    // color: #318a86 !important;
   
}

.button-value1 {
    // border-bottom: 2px solid var(--ion-color-primary);
    height: 27px;
    font-size: 13px !important;
    font-weight: bold !important;
    font-variant: small-caps;
    background-color: #fff;
    color: #555 !important;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom: none;
    
   
}
