.jqx-widget-content-treeiot{border-color: #a4bed4 !important;}
.jqx-fill-state-normal-treeiot, .jqx-widget-header-treeiot{border-color:#a4bed4 !important; background-color:#e0e9f5 !important}
.jqx-fill-state-hover-treeiot{border-color:#a4bed4 !important;  background-color:#cfdde9 !important}
.jqx-fill-state-pressed-treeiot{border-color:#5e8cb3 !important; background-color:#cfdde9 !important}
.jqx-fill-state-focus-treeiot { border-color: #477396 !important; }
.jqx-scrollbar-state-normal-treeiot, .jqx-grid-bottomright-treeiot, .jqx-panel-bottomright-treeiot, .jqx-listbox-bottomright-treeiot{background-color:#e0e9f5 !important;}
.jqx-widget-treeiot .jqx-grid-column-header-treeiot, .jqx-grid-cell-treeiot, .jqx-widget-treeiot .jqx-grid-cell-treeiot, .jqx-widget-treeiot .jqx-grid-group-cell-treeiot, .jqx-grid-group-cell-treeiot{border-color:#a4bed4 !important}
.jqx-tabs-title-selected-bottom-treeiot, .jqx-tabs-selection-tracker-bottom-treeiot, .jqx-tabs-title-selected-top-treeiot, .jqx-tabs-selection-tracker-top-treeiot{border-color:#a4bed4 !important; border-bottom:1px solid #fff !important; background:#fff !important;} 
.jqx-grid-cell-sort-alt-treeiot, .jqx-grid-cell-filter-alt-treeiot, .jqx-grid-cell-pinned-treeiot, .jqx-grid-cell-alt-treeiot, .jqx-grid-cell-sort-treeiot{ background-color:#e7eef8 !important;}
.jqx-grid-cell-selected-treeiot{ background-color:#cfdde9 !important;  color:#2b465e !important;}
.jqx-grid-cell-hover-treeiot{ background-color:#cfdde9 !important;}
.jqx-menu-vertical-treeiot{background: #e0e9f5 !important;}
.jqx-layout-treeiot
{
    background-color: #a4bed4;
}
/* .jqx-item-iotify:hover{
    background-color: #cfdde9 !important;
    border: 1px solid lightsteelblue !important;
} */
.jqx-tree-item-arrow-expand-treeiot.jqx-icon-arrow-down-treeiot{
    background-image: url(../img/logos/minus-dashtree.png) !important;
    background-size: 12px;
}
.jqx-tree-item-arrow-collapse-treeiot.jqx-icon-arrow-right-treeiot{
    background-image: url(../img/logos/plus-dashtree.png) !important;
    background-size: 12px;
}
/* .jqx-tree-item-arrow-expand-treeiot.jqx-icon-arrow-down-treeiot{
    background-image: url(../img/logos/DownArrow.png) !important;
    background-size: 15px;
}
.jqx-tree-item-arrow-collapse-treeiot.jqx-icon-arrow-right-treeiot{
    background-image: url(../img/logos/sideArrow.png) !important;
    background-size: 15px;
} */

/* .jqx-widget-content-treeiot{
    background: transparent !important;
    color: #fff;
} */