:root { 
    --jqx-accent-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-text-color-emsiotify: rgba(85, 85, 85, 1);
    --jqx-background-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-header-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-header-background-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-border-color-emsiotify: rgba(231, 231, 231, 1);
    --jqx-border-radius-emsiotify: 2px;
    --jqx-font-family-emsiotify: "Roboto", "Helvetice Neue", "Helvetica", "Arial", sans-serif;
    --jqx-font-size-emsiotify: 13px ;
    --jqx-hovered-state-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-hovered-state-background-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-hovered-state-content-text-color-emsiotify: rgba(51, 51, 51, 1);
    --jqx-hovered-state-content-background-color-emsiotify: rgba(221, 241, 240, 1);
    --jqx-focused-state-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-focused-state-background-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-active-state-text-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-active-state-background-color-emsiotify: rgba(44, 124, 121, 0.2);
    --jqx-arrow-up-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-up-white.png');
    --jqx-arrow-down-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down-white.png');
    --jqx-arrow-up-hovered-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-up.png');
    --jqx-arrow-down-hovered-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down.png');
    --jqx-arrow-up-selected-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-up.png');
    --jqx-arrow-down-selected-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down.png');
    --jqx-accordion-item-title-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-accordion-item-title-background-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-accordion-item-title-border-color-emsiotify: rgba(231, 231, 231, 1);
    --jqx-accordion-item-title-hovered-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-accordion-item-title-hovered-background-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-accordion-item-title-hovered-border-color-emsiotify: rgba(224, 224, 224, 1);
    --jqx-accordion-item-title-selected-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-accordion-item-title-selected-background-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-accordion-item-title-selected-border-color-emsiotify: rgba(224, 224, 224, 1);
    --jqx-accordion-item-title-focused-border-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-accordion-item-text-color-emsiotify: rgba(85, 85, 85, 1);
    --jqx-accordion-item-background-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-accordion-item-focused-text-color-emsiotify: rgba(85, 85, 85, 1);
    --jqx-accordion-item-focused-background-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-accordion-item-focused-border-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-accordion-arrow-up-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-up-white.png');
    --jqx-accordion-arrow-down-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down-white.png');
    --jqx-accordion-arrow-up-hovered-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-up.png');
    --jqx-accordion-arrow-down-hovered-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down.png');
    --jqx-accordion-arrow-down-selected-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-up-white.png');
    --jqx-button-default-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-button-default-background-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-button-default-hovered-state-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-button-default-hovered-state-background-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-button-default-focused-state-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-button-default-focused-state-background-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-button-default-active-state-text-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-button-default-active-state-background-color-emsiotify: rgba(44, 124, 121, 0.2);
    --jqx-button-primary-text-color-emsiotify: rgba(28, 168, 221, 1);
    --jqx-button-primary-background-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-button-primary-hovered-state-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-button-primary-hovered-state-background-color-emsiotify: rgba(28, 168, 221, 1);
    --jqx-button-primary-focused-state-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-button-primary-focused-state-background-color-emsiotify: rgba(28, 168, 221, 1);
    --jqx-button-primary-active-state-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-button-primary-active-state-background-color-emsiotify: rgba(28, 168, 221, 1);
    --jqx-button-success-text-color-emsiotify: rgba(92, 184, 92, 1);
    --jqx-button-success-background-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-button-success-hovered-state-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-button-success-hovered-state-background-color-emsiotify: rgba(92, 184, 92, 1);
    --jqx-button-success-focused-state-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-button-success-focused-state-background-color-emsiotify: rgba(92, 184, 92, 1);
    --jqx-button-success-active-state-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-button-success-active-state-background-color-emsiotify: rgba(92, 184, 92, 1);
    --jqx-button-warning-text-color-emsiotify: rgba(240, 173, 78, 1);
    --jqx-button-warning-background-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-button-warning-hovered-state-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-button-warning-hovered-state-background-color-emsiotify: rgba(240, 173, 78, 1);
    --jqx-button-warning-focused-state-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-button-warning-focused-state-background-color-emsiotify: rgba(240, 173, 78, 1);
    --jqx-button-warning-active-state-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-button-warning-active-state-background-color-emsiotify: rgba(240, 173, 78, 1);
    --jqx-button-danger-text-color-emsiotify: rgba(217, 83, 79, 1);
    --jqx-button-danger-background-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-button-danger-hovered-state-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-button-danger-hovered-state-background-color-emsiotify: rgba(217, 83, 79, 1);
    --jqx-button-danger-focused-state-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-button-danger-focused-state-background-color-emsiotify: rgba(217, 83, 79, 1);
    --jqx-button-danger-active-state-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-button-danger-active-state-background-color-emsiotify: rgba(217, 83, 79, 1);
    --jqx-button-info-text-color-emsiotify: rgba(91, 192, 222, 1);
    --jqx-button-info-background-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-button-info-hovered-state-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-button-info-hovered-state-background-color-emsiotify: rgba(91, 192, 222, 1);
    --jqx-button-info-focused-state-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-button-info-focused-state-background-color-emsiotify: rgba(91, 192, 222, 1);
    --jqx-button-info-active-state-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-button-info-active-state-background-color-emsiotify: rgba(91, 192, 222, 1);
    --jqx-editors-text-editors-text-color-emsiotify: rgb(240, 238, 238);
    --jqx-editors-text-editors-background-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-editors-text-editors-border-color-emsiotify: rgba(231, 231, 231, 1);
    --jqx-editors-text-editors-border-radius-emsiotify: 2px;
    --jqx-editors-text-editors-focused-border-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-editors-datеtimeinput-calendar-icon-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/icon-calendar.png');
    --jqx-editors-datеtimeinput-calendar-icon-selected-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/icon-calendar.png');
    --jqx-editors-combobox-multi-item-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-editors-combobox-multi-item-background-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-editors-calendar-header-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-editors-calendar-header-background-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-editors-calendar-arrow-left-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left-white.png');
                         --jqx-editors-calendar-arrow-right-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right-white.png');
    --jqx-editors-calendar-background-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-editors-calendar-today-cell-text-color-emsiotify: rgba(85, 85, 85, 1);
    --jqx-editors-calendar-today-cell-background-color-emsiotify: rgba(255, 255, 219, 1);
    --jqx-editors-calendar-today-cell-border-color-emsiotify: rgba(253, 192, 102, 1);
    --jqx-editors-calendar-current-month-text-color-emsiotify: rgba(85, 85, 85, 1);
    --jqx-editors-calendar-other-month-text-color-emsiotify: rgba(137, 137, 137, 1);
    --jqx-editors-calendar-hovered-cell-text-color-emsiotify: rgba(51, 51, 51, 1);
    --jqx-editors-calendar-hovered-cell-background-color-emsiotify: rgba(221, 241, 240, 1);
    --jqx-editors-calendar-selected-cell-text-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-editors-calendar-selected-cell-background-color-emsiotify: rgba(44, 124, 121, 0.2);
    --jqx-editors-switch-thumb-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-editors-switch-on-label-text-color-emsiotify: rgba(85, 85, 85, 1);
    --jqx-editors-switch-on-label-background-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-editors-switch-off-label-text-color-emsiotify: rgba(85, 85, 85, 1);
    --jqx-editors-switch-off-label-background-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-editors-file-uploader-background-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-editors-file-uploader-filename-text-color-emsiotify: rgba(85, 85, 85, 1);
    --jqx-grid-text-color-emsiotify: rgba(85, 85, 85, 1);
    --jqx-grid-background-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-grid-header-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-grid-header-text-weight-emsiotify: normal;
    --jqx-grid-header-background-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-grid-row-text-color-emsiotify: rgba(85, 85, 85, 1);
    --jqx-grid-row-background-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-grid-row-alternation-text-color-emsiotify: rgba(85, 85, 85, 1);
    --jqx-grid-row-alternation-background-color-emsiotify: rgba(249, 249, 249, 1);
    --jqx-grid-hovered-row-text-color-emsiotify: rgba(51, 51, 51, 1);
    --jqx-grid-hovered-row-background-color-emsiotify: rgba(221, 241, 240, 1);
    --jqx-grid-selected-row-text-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-grid-selected-row-background-color-emsiotify: rgba(44, 124, 121, 0.2);
    --jqx-grid-selected-row-border-color-emsiotify: rgba(44, 124, 121, 0.2);
    --jqx-grid-group-row-text-color-emsiotify: rgba(85, 85, 85, 1);
    --jqx-grid-group-row-background-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-grid-editor-text-color-emsiotify: rgba(85, 85, 85, 1);
    --jqx-grid-editor-background-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-grid-pinned-cells-background-color-emsiotify: rgba(229, 229, 229, 1);
    --jqx-grid-arrow-down-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down-white.png');
    --jqx-grid-arrow-right-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right-white.png');
    --jqx-grid-arrow-left-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left-white.png');
    --jqx-grid-close-button-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/close_white.png');
    --jqx-grid-arrow-down-hovered-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down.png');
    --jqx-grid-arrow-right-hovered-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right.png');
    --jqx-grid-arrow-left-hovered-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left.png');
    --jqx-grid-arrow-down-selected-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down.png');
    --jqx-grid-arrow-right-selected-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right.png');
    --jqx-grid-arrow-left-selected-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left.png');
    --jqx-grid-header-arrow-down-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down-white.png');
    --jqx-grid-header-arrow-up-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-up-white.png');
    --jqx-grid-menu-button-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/icon-menu-small-white.png');
    --jqx-grid-groups-arrow-down-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down-white.png');
    --jqx-grid-groups-arrow-right-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right-white.png');
    --jqx-grid-groups-arrow-left-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left-white.png');
    --jqx-list-text-color-emsiotify: rgba(85, 85, 85, 1);
    --jqx-list-background-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-list-header-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-list-header-background-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-list-hovered-item-state-text-color-emsiotify: rgba(51, 51, 51, 1);
    --jqx-list-hovered-item-state-background-color-emsiotify: rgba(221, 241, 240, 1);
    --jqx-list-selected-item-state-text-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-list-selected-item-state-background-color-emsiotify: rgba(44, 124, 121, 0.2);
    --jqx-list-arrow-left-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left-white.png');
    --jqx-list-arrow-right-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right-white.png');
    --jqx-list-arrow-left-selected-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left.png');
    --jqx-list-arrow-right-selected-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right.png');
    --jqx-menu-text-color-emsiotify: rgba(85, 85, 85, 1);
    --jqx-menu-background-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-menu-dropdown-text-color-emsiotify: rgba(85, 85, 85, 1);
    --jqx-menu-dropdown-background-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-menu-arrow-up-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-up-white.png');
    --jqx-menu-arrow-down-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down-white.png');
    --jqx-menu-arrow-left-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left-white.png');
    --jqx-menu-arrow-right-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right-white.png');
    --jqx-menu-hovered-item-color-emsiotify: rgba(51, 51, 51, 1);
    --jqx-menu-hovered-item-background-color-emsiotify: rgba(221, 241, 240, 1);
    --jqx-menu-selected-item-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-menu-selected-item-background-color-emsiotify: rgba(44, 124, 121, 0.2);
    --jqx-menu-selected-item-border-color-emsiotify: rgba(44, 124, 121, 0.2);
    --jqx-menu-arrow-up-selected-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-up.png');
    --jqx-menu-arrow-down-selected-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down.png');
    --jqx-menu-arrow-left-selected-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left.png');
    --jqx-menu-arrow-right-selected-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right.png');
    --jqx-navbar-item-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-navbar-item-background-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-navbar-hovered-item-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-navbar-hovered-item-background-color-emsiotify: rgba(44, 124, 121, 0.9);
    --jqx-navbar-selected-item-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-navbar-selected-item-background-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-overlays-content-text-color-emsiotify: rgba(85, 85, 85, 1);
    --jqx-overlays-content-background-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-overlays-shader-background-color-emsiotify: rgba(0, 0, 0, 0.6);
    --jqx-overlays-popup-header-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-overlays-popup-header-background-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-overlays-tooltip-text-color-emsiotify: rgba(85, 85, 85, 1);
    --jqx-overlays-tooltip-background-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-overlays-toast-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-overlays-toast-info-background-color-emsiotify: rgba(91, 192, 222, 1);
    --jqx-overlays-toast-warning-background-color-emsiotify: rgba(240, 173, 78, 1);
    --jqx-overlays-toast-error-background-color-emsiotify: rgba(217, 83, 79, 1);
    --jqx-overlays-toast-success-background-color-emsiotify: rgba(92, 184, 92, 1);
    --jqx-pivotgrid-header-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-pivotgrid-header-background-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-pivotgrid-cell-text-color-emsiotify: rgba(85, 85, 85, 1);
    --jqx-pivotgrid-cell-background-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-pivotgrid-selected-cell-text-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-pivotgrid-selected-cell-background-color-emsiotify: rgba(44, 124, 121, 0.2);
    --jqx-pivotgrid-arrow-up-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-up-white.png');
    --jqx-pivotgrid-arrow-down-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down-white.png');
    --jqx-pivotgrid-menu-button-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/icon-menu-small-white.png');
    --jqx-progressbar-bar-background-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-progressbar-selected-range-background-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-progressbar-label-color-emsiotify: rgba(85, 85, 85, 1);
    --jqx-scheduler-header-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-scheduler-header-background-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-scheduler-header-border-color-emsiotify: rgba(231, 231, 231, 1);
    --jqx-scheduler-header-buttons-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-scheduler-header-buttons-background-color-emsiotify: rgba(85, 139, 47, 1);
    --jqx-scheduler-header-buttons-hovered-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-scheduler-header-buttons-hovered-background-color-emsiotify: rgba(85, 139, 47, 1);
    --jqx-scheduler-header-buttons-selected-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-scheduler-header-buttons-selected-background-color-emsiotify: rgba(85, 139, 47, 1);
    --jqx-scheduler-border-color-emsiotify: rgba(231, 231, 231, 1);
    --jqx-scheduler-not-work-time-cells-color-emsiotify: rgba(238, 238, 238, 1);
    --jqx-scheduler-hovered-cell-background-color-emsiotify: rgba(221, 241, 240, 1);
    --jqx-scheduler-hovered-cell-border-color-emsiotify: rgba(221, 241, 240, 1);
    --jqx-scheduler-selected-cell-background-color-emsiotify: rgba(44, 124, 121, 0.2);
    --jqx-scheduler-selected-cell-border-color-emsiotify: rgba(44, 124, 121, 0.2);
    --jqx-scheduler-weeks-arrow-right-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right-white.png');
    --jqx-scheduler-weeks-arrow-left-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left-white.png');
    --jqx-scheduler-weeks-arrow-right-selected-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right.png');
    --jqx-scheduler-weeks-arrow-left-selected-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left.png');
    --jqx-scheduler-calendar-icon-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/icon-calendar.png');
    -jqx-light-scheduler-calendar-icon-selected-color: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/icon-calendar.png');
    --jqx-scrollbar-scrollbar-background-color-emsiotify: rgba(245, 245, 245, 1);
    --jqx-scrollbar-scrollbar-thumb-background-color-emsiotify: rgba(245, 245, 245, 1);
    --jqx-scrollbar-scrollbar-thumb-hovered-background-color-emsiotify: rgba(230, 230, 230, 1);
    --jqx-scrollbar-scrollbar-thumb-active-background-color-emsiotify: rgba(217, 217, 217, 1);
    --jqx-scrollbar-scrollbar-arrow-background-color-emsiotify: rgba(245, 245, 245, 1);
    --jqx-scrollbar-arrow-up-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-up.png');
    --jqx-scrollbar-arrow-down-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down.png');
    --jqx-scrollbar-arrow-left-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left.png');
    --jqx-scrollbar-arrow-right-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right.png');
    --jqx-scrollbar-scrollbar-arrow-hovered-background-color-emsiotify: rgba(232, 232, 232, 1);
    --jqx-scrollbar-arrow-up-hovered-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-up.png');
    --jqx-scrollbar-arrow-down-hovered-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down.png');
    --jqx-scrollbar-arrow-left-hovered-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left.png');
    --jqx-scrollbar-arrow-right-hovered-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right.png');
    --jqx-scrollbar-scrollbar-arrow-active-background-color-emsiotify: rgba(217, 217, 217, 1);
    --jqx-scrollbar-arrow-up-active-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-up.png');
    --jqx-scrollbar-arrow-down-active-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down.png');
    --jqx-scrollbar-arrow-left-active-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left.png');
    --jqx-scrollbar-arrow-right-active-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right.png');
    --jqx-scrollview-indicator-background-color-emsiotify: rgba(250, 250, 250, 1);
    --jqx-scrollview-selected-indicator-background-color-emsiotify: rgba(250, 250, 250, 1);
    --jqx-slider-slider-bar-background-color-emsiotify: rgba(240, 240, 240, 1);
    --jqx-slider-selected-slider-background-color-emsiotify: rgba(44, 124, 121, 0.2);
    --jqx-slider-slider-thumb-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-slider-focused-border-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-slider-tooltip-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-slider-tooltip-background-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-slider-tooltip-border-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-slider-arrows-background-color-emsiotify: rgba(255, 255, 255, 0);
    --jqx-slider-arrows-hovered-background-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-slider-arrows-selected-background-color-emsiotify: rgba(44, 124, 121, 0.2);
    --jqx-slider-arrow-left-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left-white.png');
    --jqx-slider-arrow-right-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right-white.png');
    --jqx-slider-arrow-left-hovered-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left.png');
    --jqx-slider-arrow-right-hovered-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right.png');
    --jqx-slider-arrow-left-selected-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left.png');
    --jqx-slider-arrow-right-selected-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right.png');
    --jqx-tabs-header-background-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-tabs-tab-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-tabs-hovered-tab-text-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-tabs-hovered-tab-background-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-tabs-selected-tab-text-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-tabs-selected-tab-background-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-tabs-selected-tab-bottom-border-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-tabs-border-color-emsiotify: rgba(231, 231, 231, 1);
    --jqx-tabs-content-text-color-emsiotify: rgba(85, 85, 85, 1);
    --jqx-tabs-content-background-color-emsiotify: rgba(255, 255, 255, 1);
    --jqx-tabs-arrow-left-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-left-white.png');
    --jqx-tabs-arrow-right-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-right-white.png');
    --jqx-toolbar-background-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-treeview-hovered-tree-item-color-emsiotify: rgba(51, 51, 51, 1);
    --jqx-treeview-hovered-tree-item-background-color-emsiotify: rgba(221, 241, 240, 1);
    --jqx-treeview-selected-tree-item-color-emsiotify: rgba(44, 124, 121, 1);
    --jqx-treeview-selected-tree-item-background-color-emsiotify: rgba(44, 124, 121, 0.2);
    --jqx-treeview-arrow-expanded-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down-white.png');
    --jqx-treeview-arrow-collapsed-color-emsiotify: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/metro-icon-down-white.png');
 }
 
 /*Rounded Corners*/
 /*top-left rounded Corners*/
 .jqx-rc-tl-emsiotify {
     -moz-border-radius-topleft: var(--jqx-border-radius-emsiotify);
     -webkit-border-top-left-radius: var(--jqx-border-radius-emsiotify);
     border-top-left-radius: var(--jqx-border-radius-emsiotify);
 }
 /*top-right rounded Corners*/
 .jqx-rc-tr-emsiotify {
     -moz-border-radius-topright: var(--jqx-border-radius-emsiotify);
     -webkit-border-top-right-radius: var(--jqx-border-radius-emsiotify);
     border-top-right-radius: var(--jqx-border-radius-emsiotify);
 }
 /*bottom-left rounded Corners*/
 .jqx-rc-bl-emsiotify {
     -moz-border-radius-bottomleft: var(--jqx-border-radius-emsiotify);
     -webkit-border-bottom-left-radius: var(--jqx-border-radius-emsiotify);
     border-bottom-left-radius: var(--jqx-border-radius-emsiotify);
 }
 /*bottom-right rounded Corners*/
 .jqx-rc-br-emsiotify {
     -moz-border-radius-bottomright: var(--jqx-border-radius-emsiotify);
     -webkit-border-bottom-right-radius: var(--jqx-border-radius-emsiotify);
     border-bottom-right-radius: var(--jqx-border-radius-emsiotify);
 }
 /*top rounded Corners*/
 .jqx-rc-t-emsiotify {
     -moz-border-radius-topleft: var(--jqx-border-radius-emsiotify);
     -webkit-border-top-left-radius: var(--jqx-border-radius-emsiotify);
     border-top-left-radius: var(--jqx-border-radius-emsiotify);
     -moz-border-radius-topright: var(--jqx-border-radius-emsiotify);
     -webkit-border-top-right-radius: var(--jqx-border-radius-emsiotify);
     border-top-right-radius: var(--jqx-border-radius-emsiotify);
 }
 /*bottom rounded Corners*/
 .jqx-rc-b-emsiotify {
     -moz-border-radius-bottomleft: var(--jqx-border-radius-emsiotify);
     -webkit-border-bottom-left-radius: var(--jqx-border-radius-emsiotify);
     border-bottom-left-radius: var(--jqx-border-radius-emsiotify);
     -moz-border-radius-bottomright: var(--jqx-border-radius-emsiotify);
     -webkit-border-bottom-right-radius: var(--jqx-border-radius-emsiotify);
     border-bottom-right-radius: var(--jqx-border-radius-emsiotify);
 }
 /*right rounded Corners*/
 .jqx-rc-r-emsiotify {
     -moz-border-radius-topright: var(--jqx-border-radius-emsiotify);
     -webkit-border-top-right-radius: var(--jqx-border-radius-emsiotify);
     border-top-right-radius: var(--jqx-border-radius-emsiotify);
     -moz-border-radius-bottomright: var(--jqx-border-radius-emsiotify);
     -webkit-border-bottom-right-radius: var(--jqx-border-radius-emsiotify);
     border-bottom-right-radius: var(--jqx-border-radius-emsiotify);
 }
 /*left rounded Corners*/
 .jqx-rc-l-emsiotify {
     -moz-border-radius-topleft: var(--jqx-border-radius-emsiotify);
     -webkit-border-top-left-radius: var(--jqx-border-radius-emsiotify);
     border-top-left-radius: var(--jqx-border-radius-emsiotify);
     -moz-border-radius-bottomleft: var(--jqx-border-radius-emsiotify);
     -webkit-border-bottom-left-radius: var(--jqx-border-radius-emsiotify);
     border-bottom-left-radius: var(--jqx-border-radius-emsiotify);
 }
 /*all rounded Corners*/
 .jqx-rc-all-emsiotify {
     -moz-border-radius: var(--jqx-border-radius-emsiotify);
     -webkit-border-radius: var(--jqx-border-radius-emsiotify);
     border-radius: var(--jqx-border-radius-emsiotify);
 }
 
 .jqx-widget-emsiotify, .jqx-widget-header-emsiotify, .jqx-fill-state-normal-emsiotify,
 .jqx-widget-content-emsiotify, .s, .jqx-fill-state-pressed-emsiotify {
     font-family: var(--jqx-font-family-emsiotify);
     font-size: var(--jqx-font-size-emsiotify);
 }
 
 .jqx-widget-emsiotify {
     font-family: var(--jqx-font-family-emsiotify);
     font-size: var(--jqx-font-size-emsiotify);
     color: var(--jqx-text-color-emsiotify);
 }
 
 .jqx-widget-content-emsiotify {
     font-family: var(--jqx-font-family-emsiotify);
     font-size: var(--jqx-font-size-emsiotify);
     color: var(--jqx-text-color-emsiotify);
     background-color: var(--jqx-background-color-emsiotify);
     border-color: var(--jqx-border-color-emsiotify);
 }
 
 .jqx-widget-header-emsiotify {
     background-color: var(--jqx-header-background-color-emsiotify);
     border-color: var(--jqx-border-color-emsiotify);
     color: var(--jqx-header-text-color-emsiotify);
     font-weight: 500;
     *zoom: 1;
     font-family: var(--jqx-font-family-emsiotify);
     background: var(--jqx-header-background-color-emsiotify);;
     color: var(--jqx-header-text-color-emsiotify);
     font-size: var(--jqx-font-size-emsiotify);
 }
 
 
 .jqx-fill-state-normal-emsiotify {
     border-color: var(--jqx-accent-color-emsiotify);
     *zoom: 1;
     background: var(--jqx-background-color-emsiotify);
 }
 .jqx-widget-emsiotify input::selection, input.jqx-input-widget-emsiotify::selection, .jqx-widget-content-emsiotify input::selection {
     background: var(--jqx-accent-color-emsiotify);
     color: var(--jqx-background-color-emsiotify);
 }
 .jqx-toolbar-emsiotify{
     background-color: var(--jqx-toolbar-background-color-emsiotify);
      border-color: var(--jqx-border-color-emsiotify);
 }
 
 .jqx-button-emsiotify, jqx-button-emsiotify.jqx-fill-state-normal-emsiotify {
     color: var(--jqx-button-default-text-color-emsiotify);
     background: var(--jqx-button-default-background-color-emsiotify);
     border-color: var(--jqx-button-default-background-color-emsiotify);
     *zoom: 1;
     outline: none;
     transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
     border-radius: var(--jqx-border-radius-emsiotify);
 }
 .jqx-button-emsiotify button, jqx-button-emsiotify input {
     background: transparent;
     color: inherit;
     border:none;
     outline: none;
 }
 
 
 .jqx-slider-button-emsiotify {
     padding:3px;
     background: transparent;
     border:transparent;
 }
     .jqx-button-emsiotify.float {
         border-radius: 100%;
         min-height: 48px;
         min-width: 48px;
         width: 48px;
         height: 48px;
         max-height: 48px;
         max-width:48px;
     }
 
     .jqx-button-emsiotify.outlined {
         background: transparent;
         color: var(--jqx-accent-color-emsiotify);
         border-width: 2px;
     }
 
     .jqx-button-emsiotify.flat {
         background: transparent;
         color: var(--jqx-accent-color-emsiotify);
         border: none;
     }
 
 
 
 .jqx-fill-state-hover-emsiotify, .jqx-fill-state-focus-emsiotify {
    font-size: 12px !important;
 }
 
 .jqx-expander-header.jqx-fill-state-hover-emsiotify,
 .jqx-expander-header.jqx-fill-state-normal-emsiotify
 {
      background-color: var(--jqx-accordion-item-title-background-color-emsiotify) !important;
      border-color: var(--jqx-accordion-item-title-border-color-emsiotify);
      color: var(--jqx-accordion-item-title-text-color-emsiotify) !important;
 }
 
 .jqx-expander-header.jqx-fill-state-pressed-emsiotify {
    background-color: var(--jqx-accordion-item-title-selected-background-color-emsiotify) !important;
    border-color: var(--jqx-accordion-item-title-selected-border-color-emsiotify);
    color: var(--jqx-accordion-item-title-selected-text-color-emsiotify) !important;
 }
 
 .jqx-expander-header.jqx-fill-state-hover-emsiotify {
    background-color: var(--jqx-accordion-item-title-hovered-background-color-emsiotify) !important;
    color: var(--jqx-accordion-item-title-hovered-text-color-emsiotify) !important;
    border-color: var(--jqx-accordion-item-title-hovered-border-color-emsiotify) !important;
 }
 .jqx-expander-header.jqx-fill-state-focus-emsiotify {
    border-color: var(--jqx-accordion-item-title-focused-border-color-emsiotify) !important;
 }
 
 .jqx-expander-content-emsiotify {
     background-color: var(--jqx-accordion-item-background-color-emsiotify);
     color: var(--jqx-accordion-item-text-color-emsiotify);
 }
 
 .jqx-expander-content.jqx-fill-state-focus-emsiotify {
    border-color: var(--jqx-accordion-item-focused-border-color-emsiotify) !important;
    background-color: var(--jqx-accordion-item-focused-background-color-emsiotify);
    color: var(--jqx-accordion-item-focused-text-color-emsiotify);
 }
 
 .jqx-expander-header-emsiotify {
     padding:10px;
 }
 .jqx-button-emsiotify.jqx-fill-state-hover {
     opacity: 0.9;
     cursor: pointer;
     box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
     *zoom: 1;
     color: var(--jqx-button-default-hovered-state-text-color-emsiotify);
 }
 
     .jqx-button-emsiotify.jqx-fill-state-hover.outlined,
     .jqx-button-emsiotify.jqx-fill-state-hover.flat {
         color: var(--jqx-accent-color-emsiotify);
         box-shadow: none;
     }
 
 .jqx-button-emsiotify.jqx-fill-state-pressed {
     cursor: pointer;
     background: var(--jqx-accent-color-emsiotify);
     box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
 }
 
     .jqx-button-emsiotify.jqx-fill-state-pressed.float {
         box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
     }
 
     .jqx-slider-button-emsiotify.jqx-fill-state-pressed-emsiotify,
     .jqx-button-emsiotify.jqx-fill-state-pressed.outlined,
     .jqx-button-emsiotify.jqx-fill-state-pressed.flat {
         background: rgba(179,229,252,0.15);
         box-shadow: none;
         color: var(--jqx-accent-color-emsiotify); 
     }
 
 .jqx-button-emsiotify.jqx-fill-state-focus {
     background: var(--jqx-button-default-focused-state-background-color-emsiotify);
     box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
 }
   .jqx-slider-button-emsiotify.jqx-fill-state-focus-emsiotify {
       background: transparent;
       border-color: transparent;
       box-shadow:none;
   }
   
     .jqx-button-emsiotify.jqx-fill-state-focus.outlined,
     .jqx-button-emsiotify.jqx-fill-state-focus.flat {
         box-shadow: none;
         background: rgba(99,0,238,0.15);
         color: #55882F;
     }
 
 .jqx-dropdownlist-state-normal-emsiotify, .jqx-dropdownlist-state-hover-emsiotify, .jqx-dropdownlist-state-selected-emsiotify,
 .jqx-scrollbar-button-state-hover-emsiotify, .jqx-scrollbar-button-state-normal-emsiotify, .jqx-scrollbar-button-state-pressed-emsiotify,
 .jqx-scrollbar-thumb-state-normal-horizontal-emsiotify, .jqx-scrollbar-thumb-state-hover-horizontal-emsiotify, .jqx-scrollbar-thumb-state-pressed-horizontal-emsiotify,
 .jqx-scrollbar-thumb-state-normal-emsiotify, .jqx-scrollbar-thumb-state-pressed-emsiotify, .jqx-tree-item-hover-emsiotify, .jqx-tree-item-selected-emsiotify,
 .jqx-tree-item-emsiotify, .jqx-menu-item-emsiotify, .jqx-menu-item-hover-emsiotify, .jqx-menu-item-selected-emsiotify, .jqx-menu-item-top-emsiotify, .jqx-menu-item-top-hover-emsiotify,
 .jqx-menu-item-top-selected-emsiotify, .jqx-slider-button-emsiotify, .jqx-slider-slider-emsiotify {
     -webkit-transition: background-color 100ms linear;
     -moz-transition: background-color 100ms linear;
     -o-transition: background-color 100ms linear;
     -ms-transition: background-color 100ms linear;
     transition: background-color 100ms linear;
 }
 
 
 .jqx-primary-emsiotify.jqx-input-label-emsiotify {
    color: var(--jqx-button-primary-text-color-emsiotify) !important; 
 }
 .jqx-primary-emsiotify.jqx-input-bar-emsiotify:before {
    background: var(--jqx-button-primary-text-color-emsiotify) !important;
 }
 .jqx-success-emsiotify.jqx-input-label-emsiotify {
    color: var(--jqx-button-success-text-color-emsiotify) !important;
 }
 .jqx-success-emsiotify.jqx-input-bar-emsiotify:before {
    background: var(--jqx-button-success-text-color-emsiotify) !important;
 }
 .jqx-inverse-emsiotify.jqx-input-label-emsiotify {
    color: #666 !important;
 }
 .jqx-inverse-emsiotify.jqx-input-bar-emsiotify:before {
    background: #666 !important;
 }
 .jqx-danger-emsiotify.jqx-input-label-emsiotify {
    color: var(--jqx-button-danger-text-color-emsiotify) !important;
 }
 .jqx-danger-emsiotify.jqx-input-bar-emsiotify:before {
    background: var(--jqx-button-danger-text-color-emsiotify) !important;
 }
 .jqx-warning-emsiotify.jqx-input-label-emsiotify {
    color: var(--jqx-button-warning-text-color-emsiotify) !important;
 }
 .jqx-warning-emsiotify.jqx-input-bar-emsiotify:before {
    background: var(--jqx-button-warning-text-color-emsiotify) !important;
 }
 .jqx-info-emsiotify.jqx-input-label-emsiotify {
    color: var(--jqx-button-info-text-color-emsiotify) !important;
 }
 .jqx-info-emsiotify.jqx-input-bar-emsiotify:before {
    background: var(--jqx-button-info-text-color-emsiotify) !important;
 }
 
 .jqx-slider-tooltip-emsiotify.jqx-primary-slider, .jqx-slider-tooltip-emsiotify.jqx-primary-slider .jqx-fill-state-normal-emsiotify {
     border-color: var(--jqx-button-primary-text-color-emsiotify);
     background: var(--jqx-button-primary-text-color-emsiotify);
 }
 .jqx-slider-tooltip-emsiotify.jqx-success-slider, .jqx-slider-tooltip-emsiotify.jqx-success-slider .jqx-fill-state-normal-emsiotify {
     border-color: var(--jqx-button-success-text-color-emsiotify);
     background: var(--jqx-button-success-text-color-emsiotify);
 }
 .jqx-slider-tooltip-emsiotify.jqx-inverse-slider, .jqx-slider-tooltip-emsiotify.jqx-inverse-slider .jqx-fill-state-normal-emsiotify {
     border-color: #666;
     background: #666;
 }
 .jqx-slider-tooltip-emsiotify.jqx-danger-slider, .jqx-slider-tooltip-emsiotify.jqx-danger-slider .jqx-fill-state-normal-emsiotify {
     border-color: var(--jqx-button-danger-text-color-emsiotify);
     background: var(--jqx-button-danger-text-color-emsiotify);
 }
 .jqx-slider-tooltip-emsiotify.jqx-warning-slider, .jqx-slider-tooltip-emsiotify.jqx-warning-slider .jqx-fill-state-normal-emsiotify {
     border-color: var(--jqx-button-warning-text-color-emsiotify);
     background: var(--jqx-button-warning-text-color-emsiotify);
 }
 .jqx-slider-tooltip-emsiotify.jqx-info-slider, .jqx-slider-tooltip-emsiotify.jqx-info-slider .jqx-fill-state-normal-emsiotify {
     border-color: var(--jqx-button-info-text-color-emsiotify);;
     background: var(--jqx-button-info-text-color-emsiotify);;
 }
 
 .jqx-primary-emsiotify {
     color: var(--jqx-button-primary-text-color-emsiotify) !important;
     background: var(--jqx-button-primary-background-color-emsiotify) !important;
     border-color: var(--jqx-button-primary-text-color-emsiotify) !important;
     text-shadow: none !important;
 }
 
     .jqx-primary-emsiotify.jqx-dropdownlist-state-normal-emsiotify,
     .jqx-primary-emsiotify.jqx-slider-button-emsiotify,
     .jqx-primary-emsiotify.jqx-slider-slider-emsiotify,
     .jqx-primary-emsiotify.jqx-combobox-arrow-normal-emsiotify,
     .jqx-primary-emsiotify.jqx-combobox-arrow-hover-emsiotify,
     .jqx-primary-emsiotify.jqx-action-button-emsiotify,
     .jqx-primary-emsiotify:hover,    
     .jqx-primary-emsiotify:active,
     .jqx-primary-emsiotify.active,
     .jqx-primary-emsiotify.disabled,
     .jqx-primary-emsiotify[disabled] {
         color: var(--jqx-button-primary-hovered-state-text-color-emsiotify) !important;
         background: var(--jqx-button-primary-hovered-state-background-color-emsiotify) !important;
         border-color: var(--jqx-button-primary-hovered-state-background-color-emsiotify) !important;
         text-shadow: none !important;
     }
 
     .jqx-primary-emsiotify:focus {
         color: var(--jqx-button-primary-focused-state-text-color-emsiotify) !important;
         background: var(--jqx-button-primary-focused-state-background-color-emsiotify) !important;
         border-color: var(--jqx-button-primary-focused-state-background-color-emsiotify) !important;
     }
 
     .jqx-fill-state-pressed-emsiotify.jqx-primary-emsiotify,
     .jqx-primary-emsiotify:active,
     .jqx-primary-emsiotify.active {
         color:var(--jqx-button-primary-active-state-text-color-emsiotify) !important;
         background-color: var(--jqx-button-primary-active-state-background-color-emsiotify) !important;
         border-color: var(--jqx-button-primary-active-state-background-color-emsiotify) !important;
         text-shadow: none !important;
     }
 
 .jqx-success-emsiotify {
     color: var(--jqx-button-success-text-color-emsiotify) !important;
     background: var(--jqx-button-success-background-color-emsiotify) !important;
     border-color: var(--jqx-button-success-text-color-emsiotify) !important;
     text-shadow: none !important;
 }
 
     .jqx-success-emsiotify.jqx-dropdownlist-state-normal-emsiotify,
     .jqx-success-emsiotify.jqx-slider-button-emsiotify,
     .jqx-success-emsiotify.jqx-slider-slider-emsiotify,
     .jqx-success-emsiotify.jqx-combobox-arrow-normal-emsiotify,
     .jqx-success-emsiotify.jqx-combobox-arrow-hover-emsiotify,
     .jqx-success-emsiotify.jqx-action-button-emsiotify,
     .jqx-success-emsiotify:hover
     .jqx-success-emsiotify:active,
     .jqx-success-emsiotify.active,
     .jqx-success-emsiotify.disabled,
     .jqx-success-emsiotify[disabled] {
         color: var(--jqx-button-success-hovered-state-text-color-emsiotify) !important;
         background: var(--jqx-button-success-hovered-state-background-color-emsiotify) !important;
         border-color: var(--jqx-button-success-hovered-state-background-color-emsiotify) !important;
         text-shadow: none !important;
     }
 
     .jqx-success-emsiotify:focus {
         color: var(--jqx-button-success-focused-state-text-color-emsiotify) !important;
         background: var(--jqx-button-success-focused-state-background-color-emsiotify) !important;
         border-color: var(--jqx-button-success-focused-state-background-color-emsiotify) !important;
     }
 
     .jqx-fill-state-pressed-emsiotify.jqx-success-emsiotify,
     .jqx-success-emsiotify:active,
     .jqx-success-emsiotify.active {
         color:var(--jqx-button-success-active-state-text-color-emsiotify) !important;
         background-color: var(--jqx-button-success-active-state-background-color-emsiotify) !important;
         border-color: var(--jqx-button-success-active-state-background-color-emsiotify) !important;
         text-shadow: none !important;
     }
 
 .jqx-inverse-emsiotify {
     text-shadow: none !important;
     color: #666 !important;
     background: #fff !important;
     border-color: #cccccc !important;
 }
 
     .jqx-inverse-emsiotify.jqx-dropdownlist-state-normal-emsiotify,
     .jqx-inverse-emsiotify.jqx-slider-button-emsiotify,
     .jqx-inverse-emsiotify.jqx-slider-slider-emsiotify,
     .jqx-inverse-emsiotify.jqx-combobox-arrow-hover-emsiotify,
     .jqx-inverse-emsiotify.jqx-combobox-arrow-normal-emsiotify,
     .jqx-inverse-emsiotify.jqx-action-button-emsiotify,
     .jqx-inverse-emsiotify:hover,
     .jqx-inverse-emsiotify:focus,
     .jqx-inverse-emsiotify:active,
     .jqx-inverse-emsiotify.active,
     .jqx-inverse-emsiotify.disabled,
     .jqx-inverse-emsiotify[disabled] {
         text-shadow: none !important;
         color: #666 !important;
         background: #cccccc !important;
         border-color: #cccccc !important;
     }
 
     .jqx-fill-state-pressed-emsiotify.jqx-inverse-emsiotify,
     .jqx-inverse-emsiotify:active,
     .jqx-inverse-emsiotify.active {
         text-shadow: none !important;
         color: #666 !important;
         background: #cccccc !important;
         border-color: #cccccc !important;
     }
 
 
 .jqx-danger-emsiotify {
     text-shadow: none !important;
     color: var(--jqx-button-danger-text-color-emsiotify) !important;
     background: var(--jqx-button-danger-background-color-emsiotify) !important;
     border-color: var(--jqx-button-danger-text-color-emsiotify) !important;
 }
 
     .jqx-danger-emsiotify.jqx-dropdownlist-state-normal-emsiotify,
     .jqx-danger-emsiotify.jqx-slider-button-emsiotify,
     .jqx-danger-emsiotify.jqx-slider-slider-emsiotify,
     .jqx-danger-emsiotify.jqx-combobox-arrow-hover-emsiotify,
     .jqx-danger-emsiotify.jqx-combobox-arrow-normal-emsiotify,
     .jqx-danger-emsiotify.jqx-action-button-emsiotify,
     .jqx-danger-emsiotify:hover,
     .jqx-danger-emsiotify:active,
     .jqx-danger-emsiotify.active,
     .jqx-danger-emsiotify.disabled,
     .jqx-danger-emsiotify[disabled] {
         text-shadow: none !important;
         color: var(--jqx-button-danger-hovered-state-text-color-emsiotify) !important;
         background: var(--jqx-button-danger-hovered-state-background-color-emsiotify) !important;
         border-color: var(--jqx-button-danger-hovered-state-background-color-emsiotify) !important;
     }
 
     .jqx-danger-emsiotify:focus {
         color: var(--jqx-button-danger-focused-state-text-color-emsiotify) !important;
         background: var(--jqx-button-danger-focused-state-background-color-emsiotify) !important;
         border-color: var(--jqx-button-danger-focused-state-background-color-emsiotify) !important;
     }
 
     .jqx-fill-state-pressed-emsiotify.jqx-danger-emsiotify,
     .jqx-danger-emsiotify:active,
     .jqx-danger-emsiotify.active {
         text-shadow: none !important;
         color:var(--jqx-button-danger-active-state-text-color-emsiotify) !important;
         background-color: var(--jqx-button-danger-active-state-background-color-emsiotify) !important;
         border-color: var(--jqx-button-danger-active-state-background-color-emsiotify) !important;
     }
 
 .jqx-validator-error-label-emsiotify {
     color: #d9534f !important;
 }
 
 .jqx-warning-emsiotify {
     text-shadow: none !important;
     color: var(--jqx-button-warning-text-color-emsiotify) !important;
     background: var(--jqx-button-warning-background-color-emsiotify) !important;
     border-color: var(--jqx-button-warning-text-color-emsiotify) !important;
 }
 
     .jqx-warning-emsiotify.jqx-dropdownlist-state-normal-emsiotify,
     .jqx-warning-emsiotify.jqx-slider-button-emsiotify,
     .jqx-warning-emsiotify.jqx-slider-slider-emsiotify,
     .jqx-warning-emsiotify.jqx-combobox-arrow-hover-emsiotify,
     .jqx-warning-emsiotify.jqx-combobox-arrow-normal-emsiotify,
     .jqx-warning-emsiotify.jqx-action-button-emsiotify,
     .jqx-warning-emsiotify:hover
     .jqx-warning-emsiotify:active,
     .jqx-warning-emsiotify.active,
     .jqx-warning-emsiotify.disabled,
     .jqx-warning-emsiotify[disabled] {
         text-shadow: none !important;
         color: var(--jqx-button-warning-hovered-state-text-color-emsiotify) !important;
         background: var(--jqx-button-warning-hovered-state-background-color-emsiotify) !important;
         border-color: var(--jqx-button-warning-hovered-state-background-color-emsiotify) !important;
     }
 
     .jqx-warning-emsiotify:focus {
         color: var(--jqx-button-warning-focused-state-text-color-emsiotify) !important;
         background: var(--jqx-button-warning-focused-state-background-color-emsiotify) !important;
         border-color: var(--jqx-button-warning-focused-state-background-color-emsiotify) !important;
     }
 
     .jqx-fill-state-pressed-emsiotify.jqx-warning-emsiotify,
     .jqx-warning-emsiotify:active,
     .jqx-warning-emsiotify.active {
         text-shadow: none !important;
         color:var(--jqx-button-warning-active-state-text-color-emsiotify) !important;
         background-color: var(--jqx-button-warning-active-state-background-color-emsiotify) !important;
         border-color: var(--jqx-button-warning-active-state-background-color-emsiotify) !important;
     }
 
 
 .jqx-info-emsiotify {
     text-shadow: none !important;
     color: var(--jqx-button-info-text-color-emsiotify) !important;
     background: var(--jqx-button-info-background-color-emsiotify) !important;
     border-color: var(--jqx-button-info-text-color-emsiotify) !important;
 }
 
     .jqx-info-emsiotify.jqx-dropdownlist-state-normal-emsiotify,
     .jqx-info-emsiotify.jqx-slider-button-emsiotify,
     .jqx-info-emsiotify.jqx-slider-slider-emsiotify,
     .jqx-info-emsiotify.jqx-combobox-arrow-hover-emsiotify,
     .jqx-info-emsiotify.jqx-combobox-arrow-normal-emsiotify,
     .jqx-info-emsiotify.jqx-action-button-emsiotify,
     .jqx-info-emsiotify:hover,
     .jqx-info-emsiotify:active,
     .jqx-info-emsiotify.active,
     .jqx-info-emsiotify.disabled,
     .jqx-info-emsiotify[disabled] {
         color: var(--jqx-button-info-hovered-state-text-color-emsiotify) !important;
         background: var(--jqx-button-info-hovered-state-background-color-emsiotify) !important;
         border-color: var(--jqx-button-info-hovered-state-background-color-emsiotify) !important;
         text-shadow: none !important;
     }
 
     .jqx-info-emsiotify:focus {
         color: var(--jqx-button-info-focused-state-text-color-emsiotify) !important;
         background: var(--jqx-button-info-focused-state-background-color-emsiotify) !important;
         border-color: var(--jqx-button-info-focused-state-background-color-emsiotify) !important;
     }
 
     .jqx-fill-state-pressed-emsiotify.jqx-info-emsiotify,
     .jqx-info-emsiotify:active,
     .jqx-info-emsiotify.active {
         text-shadow: none !important;
         color:var(--jqx-button-info-active-state-text-color-emsiotify) !important;
         background-color: var(--jqx-button-info-active-state-background-color-emsiotify) !important;
         border-color: var(--jqx-button-info-active-state-background-color-emsiotify) !important;
     }
 
     .jqx-loader-emsiotify, .jqx-popover-content-emsiotify {
         color: var(--jqx-overlays-content-text-color-emsiotify);
         background-color: var(--jqx-overlays-content-background-color-emsiotify);
     }
     
     .jqx-popover-modal-background-emsiotify {
         background-color: var(--jqx-overlays-shader-background-color-emsiotify);
     }
     
     .jqx-popover-title-emsiotify {
         color: var(--jqx-overlays-popup-header-text-color-emsiotify) !important;
         background-color: var(--jqx-overlays-popup-header-background-color-emsiotify) !important;
     }
     
     .jqx-loader-text-emsiotify {
         color: var(--jqx-overlays-content-text-color-emsiotify);
     }
     
     .jqx-notification-emsiotify {
         color: var(--jqx-overlays-toast-text-color-emsiotify) !important;
     }
     
     .jqx-notification-info.jqx-notification-emsiotify {
         background-color: var(--jqx-overlays-toast-info-background-color-emsiotify) !important;
         border-color: var(--jqx-overlays-toast-info-background-color-emsiotify) !important;
     }  
     
     .jqx-notification-warning.jqx-notification-emsiotify {
         background-color: var(--jqx-overlays-toast-warning-background-color-emsiotify) !important;
         border-color: var(--jqx-overlays-toast-warning-background-color-emsiotify) !important;
     }  
     
     .jqx-notification-error.jqx-notification-emsiotify {
         background-color: var(--jqx-overlays-toast-error-background-color-emsiotify) !important;
         border-color: var(--jqx-overlays-toast-error-background-color-emsiotify) !important;
     }  
     
     .jqx-notification-success.jqx-notification-emsiotify {
         background-color: var(--jqx-overlays-toast-success-background-color-emsiotify) !important;
         border-color: var(--jqx-overlays-toast-success-background-color-emsiotify) !important;
     } 
 
 .jqx-fill-state-pressed-emsiotify {
     background-image: none;
     outline: 0;
 }
 
 .jqx-grid-group-column-emsiotify {
     border-color: transparent;
 }
 .jqx-grid-column-menubutton-emsiotify {
     border-width: 0px;
 }
 .jqx-grid-groups-row-emsiotify > span {
     padding-left: 4px;
 }
 
 .jqx-grid-cell-emsiotify, .jqx-grid-group-cell-emsiotify {
     -webkit-box-shadow: none;
     -moz-box-shadow: none;
     box-shadow: none;
     background-color: var(--jqx-grid-row-background-color-emsiotify);
     color: var(--jqx-grid-row-text-color-emsiotify);
 }
 
 .jqx-grid-cell-alt-emsiotify {
     background-color: var(--jqx-grid-row-alternation-background-color-emsiotify) !important;
     color: var(--jqx-grid-row-alternation-text-color-emsiotify) !important;
 }
 
 .jqx-grid-pager-top-emsiotify .jqx-button-emsiotify,
 .jqx-grid-pager-emsiotify .jqx-button-emsiotify {
     color: rgba(0,0,0,.54) !important;
     background-color: transparent;
     border-color: transparent;
     position: relative;
     height:30px !important;
     width:30px !important;
     border-radius:50%;
     top: -4px;
 }
 
 .jqx-grid-pager-input-emsiotify  {
     padding:0px !important;
 }
 
 .jqx-grid-pager-top-emsiotify .jqx-button-emsiotify > div,
 .jqx-grid-pager-emsiotify .jqx-button-emsiotify > div {
     top: 3px;
     position: relative;
     left: 2px;
 }
 
 .jqx-grid-pager-top-emsiotify .jqx-button-emsiotify.jqx-fill-state-hover,
 .jqx-grid-pager-top-emsiotify .jqx-button-emsiotify.jqx-fill-state-pressed,
 .jqx-grid-pager-emsiotify .jqx-button-emsiotify.jqx-fill-state-hover,
 .jqx-grid-pager-emsiotify .jqx-button-emsiotify.jqx-fill-state-pressed
 {
     color: rgba(0,0,0,.54) !important;
     background-color: transparent;
     border-color: transparent;
     box-shadow: none;    
 }
 
 .jqx-grid-pager-top-emsiotify .jqx-grid-pager-number-emsiotify,
 .jqx-grid-pager-emsiotify .jqx-grid-pager-number-emsiotify {
 
     background-color: transparent;
     border-color: transparent;
     color: rgba(0,0,0,.54) !important;
     font-size:12px;
 }
 
 .jqx-grid-pager-top-emsiotify .jqx-grid-pager-number-emsiotify:hover,
 .jqx-grid-pager-emsiotify .jqx-grid-pager-number-emsiotify:hover {
     font-size:12px;
 }
 
 .jqx-grid-pager-top-emsiotify .jqx-grid-pager-number-emsiotify.jqx-fill-state-pressed-emsiotify ,
 .jqx-grid-pager-emsiotify .jqx-grid-pager-number-emsiotify.jqx-fill-state-pressed-emsiotify {
     color: var(--jqx-accent-color-emsiotify) !important;
     font-weight: bold !important;
 }
 
 .jqx-grid-column-menubutton-emsiotify {
     background-color: transparent;
     border-color: #E0E0E0 !important;
 }
 
 .jqx-grid-column-header-emsiotify, .jqx-grid-groups-header-emsiotify, .jqx-grid-pager-emsiotify {
     background-color: var(--jqx-grid-header-background-color-emsiotify) !important;
     color: var(--jqx-grid-header-text-color-emsiotify) !important;
     font-weight: var(--jqx-grid-header-text-weight-emsiotify);
 }
 
 .jqx-grid-group-expand-emsiotify + div, .jqx-grid-group-collapse-emsiotify + div {
     background-color: var(--jqx-grid-group-row-background-color-emsiotify);
     color: var(--jqx-grid-group-row-text-color-emsiotify);
 }
 
 .jqx-grid-cell-pinned-emsiotify {
     background-color: var(--jqx-grid-pinned-cells-background-color-emsiotify) !important;
 }
 
 #groupsheadergrid .jqx-fill-state-normal-emsiotify {
     background-color: var(--jqx-grid-background-color-emsiotify) !important;
 }
 
 .jqx-cell-emsiotify {
     font-size: 13px;
 }
 
 .jqx-calendar-emsiotify > div {
     padding: 10px;
     box-sizing: border-box;
 }
 
 .jqx-calendar-row-header-emsiotify, .jqx-calendar-top-left-header-emsiotify {
     background-color: #f0f0f0;
     border: 0px solid #f2f2f2;
 }
 
 .jqx-calendar-title-header-emsiotify {
     color: var(--jqx-editors-calendar-header-text-color-emsiotify);
     background-color: var(--jqx-editors-calendar-header-background-color-emsiotify);
 }
 .jqx-calendar-column-header-emsiotify {
     color: var(--jqx-editors-calendar-header-text-color-emsiotify);
     background-color: var(--jqx-editors-calendar-header-background-color-emsiotify);
     border-top: 1px solid var(--jqx-editors-calendar-header-background-color-emsiotify);
     border-bottom: 1px solid var(--jqx-editors-calendar-header-background-color-emsiotify);
 }
 
 .jqx-calendar-month-container-emsiotify {
     background-color: var(--jqx-editors-calendar-background-color-emsiotify);
 }
 
 .jqx-calendar-cell-month-emsiotify {
     color: var(--jqx-editors-calendar-current-month-text-color-emsiotify) !important;
 }
 
 .jqx-calendar-cell-othermonth-emsiotify {
     color: var(--jqx-editors-calendar-other-month-text-color-emsiotify) !important;
 }
 
 .jqx-calendar-cell-today-emsiotify {
     color: var(--jqx-editors-calendar-today-cell-text-color-emsiotify) !important;
     background-color: var(--jqx-editors-calendar-today-cell-background-color-emsiotify);
     border-color: var(--jqx-editors-calendar-today-cell-border-color-emsiotify);
 }
 
 .jqx-calendar-cell-hover-emsiotify {
     color: var(--jqx-editors-calendar-hovered-cell-text-color-emsiotify) !important;
     background-color: var(--jqx-editors-calendar-hovered-cell-background-color-emsiotify);
     border-color: var(--jqx-editors-calendar-hovered-cell-background-color-emsiotify); 
 }
 .jqx-calendar-cell-selected-emsiotify {
     color: var(--jqx-editors-calendar-selected-cell-text-color-emsiotify) !important;
     background-color: var(--jqx-editors-calendar-selected-cell-background-color-emsiotify);
     border-color: var(--jqx-editors-calendar-selected-cell-background-color-emsiotify);
 }
 
 .jqx-expander-header-emsiotify {
     padding-top: 10px;
     padding-bottom: 10px;
 }
 
 .jqx-ribbon-header-vertical-emsiotify, .jqx-widget-header-vertical-emsiotify {
     background: #fff;
 }
 
 .jqx-scrollbar-state-normal-emsiotify {
     background-color: var(--jqx-scrollbar-scrollbar-background-color-emsiotify);
     border: 1px solid #f5f5f5;
     border-left-color: #ddd;
 }
 
 .jqx-scrollbar-button-state-normal-emsiotify {
     background-color: var(--jqx-scrollbar-scrollbar-arrow-background-color-emsiotify);
 }
 
 .jqx-scrollbar-button-state-hover-emsiotify {
     background: var(--jqx-scrollbar-scrollbar-arrow-hovered-background-color-emsiotify);
 }
 
 .jqx-scrollbar-button-state-pressed-emsiotify {
     background: var(--jqx-scrollbar-scrollbar-arrow-active-background-color-emsiotify) !important;
     /* border-color: var(--jqx-scrollbar-scrollbar-arrow-active-background-color-emsiotify); */
 }
 
 .jqx-scrollbar-thumb-state-normal-emsiotify, .jqx-scrollbar-thumb-state-normal-horizontal-emsiotify {
     background: var(--jqx-scrollbar-scrollbar-thumb-background-color-emsiotify);
     border-color: #b3b3b3;
 }
 
 .jqx-scrollbar-thumb-state-hover-emsiotify, .jqx-scrollbar-thumb-state-hover-horizontal-emsiotify {
     background: var(--jqx-scrollbar-scrollbar-thumb-hovered-background-color-emsiotify);
     border-color: #b3b3b3;
     box-shadow: none;
     -webkit-box-shadow: none;
     -moz-box-shadow: none;
 }
 
 
 /* scrollbar arrow up */
 .jqx-scrollbar-button-state-normal-emsiotify .jqx-icon-arrow-up-emsiotify {
     background-image: var(--jqx-scrollbar-arrow-up-color-emsiotify);
 }
 .jqx-scrollbar-button-state-hover-emsiotify .jqx-icon-arrow-up-hover-emsiotify {
     background-image: var(--jqx-scrollbar-arrow-up-hovered-color-emsiotify);
 }
 .jqx-scrollbar-button-state-pressed-emsiotify .jqx-icon-arrow-up-selected-emsiotify {
     background-image: var(--jqx-scrollbar-arrow-up-active-color-emsiotify);
 }
 
 /* scrollbar arrow down */
 .jqx-scrollbar-button-state-normal-emsiotify .jqx-icon-arrow-down-emsiotify {
     background-image: var(--jqx-scrollbar-arrow-down-color-emsiotify);
 }
 .jqx-scrollbar-button-state-hover-emsiotify .jqx-icon-arrow-down-hover-emsiotify {
     background-image: var(--jqx-scrollbar-arrow-down-hovered-color-emsiotify);
 }
 .jqx-scrollbar-button-state-pressed-emsiotify .jqx-icon-arrow-down-selected-emsiotify {
     background-image: var(--jqx-scrollbar-arrow-down-active-color-emsiotify);
 }
 
 /* scrollbar arrow left */
 .jqx-scrollbar-button-state-normal-emsiotify .jqx-icon-arrow-left-emsiotify {
     background-image: var(--jqx-scrollbar-arrow-left-color-emsiotify);
 }
 .jqx-scrollbar-button-state-hover-emsiotify .jqx-icon-arrow-left-hover-emsiotify {
     background-image: var(--jqx-scrollbar-arrow-left-hovered-color-emsiotify);
 }
 .jqx-scrollbar-button-state-pressed-emsiotify .jqx-icon-arrow-left-selected-emsiotify{
     background-image: var(--jqx-scrollbar-arrow-left-active-color-emsiotify);
 }
 
 /* scrollbar arrow right */
 .jqx-scrollbar-button-state-normal-emsiotify .jqx-icon-arrow-right-emsiotify {
     background-image: var(--jqx-scrollbar-arrow-right-color-emsiotify);
 }
 .jqx-scrollbar-button-state-hover-emsiotify .jqx-icon-arrow-right-hover-emsiotify {
     background-image: var(--jqx-scrollbar-arrow-right-hovered-color-emsiotify);
 }
 .jqx-scrollbar-button-state-pressed-emsiotify .jqx-icon-arrow-right-selected-emsiotify {
     background-image: var(--jqx-scrollbar-arrow-right-active-color-emsiotify);
 }
 
 .jqx-scrollview-button-emsiotify {
     background-color: var(--jqx-scrollview-indicator-background-color-emsiotify);
 }
 
 .jqx-scrollview-button-selected-emsiotify {
     background-color: var(--jqx-scrollview-selected-indicator-background-color-emsiotify);
 }
 
 .jqx-progressbar-emsiotify {
     background: var(--jqx-progressbar-bar-background-color-emsiotify) !important;
     -webkit-box-shadow: none;
     -moz-box-shadow: none;
     box-shadow: none;
 }
 
 .jqx-progressbar-value-emsiotify, .jqx-progressbar-value-vertical-emsiotify {
     background: var(--jqx-progressbar-selected-range-background-color-emsiotify);
 }
 
 .jqx-progressbar-text-emsiotify {
     color: var(--jqx-progressbar-label-color-emsiotify);
 }
 
 .jqx-splitter-collapse-button-vertical-emsiotify, .jqx-splitter-collapse-button-horizontal-emsiotify {
     background: var(--jqx-accent-color-emsiotify);
 }
 
 
 .jqx-scrollbar-thumb-state-pressed-emsiotify, .jqx-splitter-splitbar-vertical-emsiotify, .jqx-splitter-splitbar-horizontal-emsiotify, .jqx-scrollbar-thumb-state-pressed-horizontal-emsiotify,
 .jqx-scrollbar-button-state-pressed-emsiotify {
     background: var(--jqx-scrollbar-scrollbar-thumb-active-background-color-emsiotify);
     border-color: #b3b3b3;
     box-shadow: none;
     -webkit-box-shadow: none;
     -moz-box-shadow: none;
 }
 
 .jqx-grid-column-sortdescbutton-emsiotify, jqx-grid-column-filterbutton-emsiotify, .jqx-grid-column-sortascbutton-emsiotify {
     background-color: transparent;
     border-style: solid;
     border-width: 0px 0px 0px 0px;
     border-color: var(--jqx-border-color-emsiotify);
 }
 
 .jqx-menu-vertical-emsiotify, .jqx-menu-horizontal-emsiotify {
     background: var(--jqx-menu-background-color-emsiotify);
     filter: none;
 }
 
 .jqx-menu-dropdown-emsiotify {
     background-color: var(--jqx-menu-dropdown-background-color-emsiotify);
 }
 
 .jqx-grid-bottomright-emsiotify, .jqx-panel-bottomright-emsiotify, .jqx-listbox-bottomright-emsiotify {
     background-color: #fafafa;
 }
 
 .jqx-window-emsiotify, .jqx-tooltip-emsiotify {
     box-shadow: 0 4px 23px 5px rgba(0, 0, 0, 0.2), 0 2px 6px rgba(0,0,0,0.15);
 }
 .jqx-tooltip-emsiotify, .jqx-tooltip-emsiotify.jqx-popup-emsiotify, .jqx-tooltip-emsiotify .jqx-fill-state-normal-emsiotify {
     background: var(--jqx-overlays-tooltip-background-color-emsiotify);
     border-color: var(--jqx-overlays-tooltip-background-color-emsiotify);
     box-shadow: none;
     color: var(--jqx-overlays-tooltip-text-color-emsiotify);
 }
 .jqx-docking-emsiotify .jqx-window-emsiotify {
     box-shadow: none;
 }
 
 .jqx-docking-panel-emsiotify .jqx-window-emsiotify {
     box-shadow: none;
 }
 
 .jqx-checkbox-emsiotify {
     line-height:20px;
     overflow: visible;
 }
 .jqx-radiobutton-emsiotify {
     overflow: visible;
     box-shadow: none;
     -webkit-box-shadow: none;
     -moz-box-shadow: none;
     background-repeat: no-repeat;
     background: none;
     line-height:20px;
 }
 
 .jqx-radiobutton-emsiotify-emsiotify, .jqx-radiobutton-hover-emsiotify {
     -moz-border-radius: 100%;
     -webkit-border-radius: 100%;
     border-radius: 100%;
     background-repeat: no-repeat;
     transition: background-color ease-in .3s;
 }
 
 .jqx-radiobutton-check-checked-emsiotify {
     filter: none;
     background: var(--jqx-background-color-emsiotify);
     background-repeat: no-repeat;
     -moz-border-radius: 100%;
     -webkit-border-radius: 100%;
     border-radius: 100%;
 }
 
 .jqx-radiobutton-check-indeterminate-emsiotify {
     filter: none;
     background: #999;
     -moz-border-radius: 100%;
     -webkit-border-radius: 100%;
     border-radius: 100%;
 }
 
 .jqx-radiobutton-check-indeterminate-disabled-emsiotify {
     filter: none;
     background: #999;
     -moz-border-radius: 100%;
     -webkit-border-radius: 100%;
     border-radius: 100%;
 }
 
 .jqx-checkbox-default-emsiotify,
 .jqx-radiobutton-default-emsiotify
  {
     border-width: 1px;
     border-color: var(--jqx-border-color-emsiotify);
     background-color: var(--jqx-background-color-emsiotify);
     overflow: visible;
 }
 
 .jqx-tree-emsiotify .jqx-checkbox-emsiotify .jqx-checkbox-default-emsiotify,
 .jqx-checkbox-emsiotify[checked] .jqx-checkbox-default-emsiotify,
 .jqx-tree-grid-checkbox[checked].jqx-checkbox-default-emsiotify,
 .jqx-radiobutton-emsiotify[checked] .jqx-radiobutton-default-emsiotify
  {
     background-color: var(--jqx-accent-color-emsiotify);
     border-color: var(--jqx-accent-color-emsiotify);
 }
 
 .jqx-checkbox-check-checked-emsiotify {
     background: transparent url(../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material_check_white.png) center center no-repeat;
 }
 .jqx-checkbox-check-indeterminate-emsiotify {
     width:14px !important;
     height:14px !important;
     position:relative;
     top: 1px;
     left: 1px;
     background: white;
 }
 .jqx-tree-emsiotify .jqx-checkbox-check-indeterminate-emsiotify {
     width:12px !important;
     height:12px !important;
     top: 2px;
     left:2px;
 }
 
 .jqx-checkbox-hover-emsiotify,
 .jqx-radiobutton-hover-emsiotify {
     background-color: var(--jqx-accent-color-emsiotify);
     border-color: var(--jqx-accent-color-emsiotify);
 }
 
 
 .jqx-slider-slider-emsiotify {
     transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.5s ease;
 }
 
 .jqx-slider-slider-emsiotify:active {
     transform: scale(1.2);
     box-shadow: rgba(0,0,0,0.3) 0 0 10px;
 }
 .jqx-slider-emsiotify[discrete] .jqx-slider-slider-emsiotify:active
  {
     transform: scaleX(0);
     
 }
 .jqx-slider-slider-horizontal-emsiotify, .jqx-slider-slider-vertical-emsiotify {
     background: var(--jqx-slider-slider-thumb-color-emsiotify);
     border-color: var(--jqx-slider-slider-thumb-color-emsiotify);  
 }
 
 .jqx-slider-has-value-emsiotify.jqx-fill-state-focus-emsiotify,
 .jqx-slider-button-emsiotify.jqx-fill-state-focus-emsiotify {
     border-color: var(--jqx-slider-focused-border-color-emsiotify) !important;
 }
 
 .jqx-slider-tooltip-emsiotify {
     width: 25px;
     height: 25px;
     transform-origin: 50% 100%;
     border-radius: 50%;
     transform: scale(0) rotate(45deg);
     padding:0px;
     background: transparent !important;
 }
 .jqx-slider-tooltip-emsiotify.init {
      transform: scale(1) rotate(45deg);
 }
 .jqx-slider-tooltip-emsiotify.hide {
      transition: transform 0.2s ease;
      transform-origin:50% 100%;
      transform: scale(0) rotate(45deg); 
 }
 .jqx-slider-tooltip-emsiotify.show {
      transition: transform 0.2s ease;
      transform: scale(1) rotate(45deg); 
 }
 
 
 .jqx-slider-tooltip-emsiotify .jqx-tooltip-arrow-t-b,
 .jqx-slider-tooltip-emsiotify .jqx-tooltip-arrow-l-r {
     display:none;
     visibility:hidden;
 }
 
 .jqx-slider-tooltip-emsiotify, .jqx-slider-tooltip-emsiotify .jqx-fill-state-normal-emsiotify {
     border-radius: 15px 15px 0px;
     display: flex;
     align-items: center;
     justify-content: center;
     background: var(--jqx-slider-tooltip-background-color-emsiotify); 
     color: var(--jqx-slider-tooltip-text-color-emsiotify);
     border-color: var(--jqx-slider-tooltip-border-color-emsiotify);
     font-size:11px;
 }
 .jqx-slider-tooltip-emsiotify.far, .jqx-slider-tooltip-emsiotify.far .jqx-fill-state-normal-emsiotify {
    border-radius: 0px 15px 15px 15px; 
 }
 .jqx-slider-tooltip-emsiotify.vertical, .jqx-slider-tooltip-emsiotify.vertical .jqx-fill-state-normal-emsiotify {
    border-radius: 15px 0px 15px 15px; 
 }
 .jqx-slider-tooltip-emsiotify.vertical.far, .jqx-slider-tooltip-emsiotify.vertical.far .jqx-fill-state-normal-emsiotify {
    border-radius: 15px 15px 15px 0px; 
 }
 .jqx-slider-tooltip-emsiotify {
     background:transparent;
     border:none !important;
     box-shadow:none;
 }
 .jqx-slider-tooltip-emsiotify .jqx-tooltip-main-emsiotify {
     top: -7px;
     right: 11px;
 }
 .jqx-slider-tooltip-emsiotify.far .jqx-tooltip-main-emsiotify {
     top: 3px;
     right: 4px;
 }
 .jqx-slider-tooltip-emsiotify.vertical .jqx-tooltip-main-emsiotify {
     top: -3px;
     right: 3px;
 }
 .jqx-slider-tooltip-emsiotify .jqx-tooltip-text {
     background: transparent;
     border:none;
     padding: 0px;
     overflow:visible;
 }
 .jqx-slider-tooltip-emsiotify .jqx-tooltip-text>span {
      transform: rotate(-45deg);
 }
 .jqx-slider-tooltip-emsiotify.range {
     width: 35px;
     height:35px;
 }
 
 .jqx-slider-rangebar-emsiotify {
     border-color: var(--jqx-slider-selected-slider-background-color-emsiotify);
     background: var(--jqx-slider-selected-slider-background-color-emsiotify);
 }
 
 .jqx-slider-track-horizontal-emsiotify, .jqx-slider-track-vertical-emsiotify {
     border-color: var(--jqx-border-color-emsiotify);
     background: var(--jqx-slider-slider-bar-background-color-emsiotify);
 }
 
 .jqx-slider-button-emsiotify {
     -moz-border-radius: 100%;
     -webkit-border-radius: 100%;
     border-radius: 100%;
 }
 .jqx-slider-button-emsiotify.jqx-fill-state-normal-emsiotify {
     background: var(--jqx-slider-arrows-background-color-emsiotify) !important;
 }
 
 .jqx-slider-button-emsiotify.jqx-fill-state-hover-emsiotify {
     background: var(--jqx-slider-arrows-hovered-background-color-emsiotify) !important;
 }
 
 .jqx-slider-button-emsiotify.jqx-fill-state-pressed-emsiotify {
     background: var(--jqx-slider-arrows-selected-background-color-emsiotify) !important;
 }
 
 .jqx-listitem-state-hover-emsiotify,
 .jqx-listitem-state-selected-emsiotify,
 .jqx-listitem-state-normal-emsiotify {
     padding: 5px;
     margin:0px;
 }
 
 .jqx-listitem-state-normal-emsiotify, .jqx-listmenu-item-emsiotify.jqx-fill-state-normal-emsiotify {
     color: var(--jqx-list-text-color-emsiotify);
     background-color: var(--jqx-list-background-color-emsiotify);
 }
 
 .jqx-listitem-state-group-emsiotify, .jqx-listmenu-header-emsiotify {
     color: var(--jqx-list-header-text-color-emsiotify);
     background-color: var(--jqx-list-header-background-color-emsiotify);
 }
 
 .jqx-menu-item-top-emsiotify {
     color: var(--jqx-menu-text-color-emsiotify);
 }
 .jqx-menu-item-emsiotify {
     color: var(--jqx-menu-dropdown-text-color-emsiotify);
 }
 
 .jqx-scheduler-edit-dialog-label-emsiotify {
   line-height: 35px;
   padding-top: 6px;
   padding-bottom: 6px;
 
 }
 .jqx-scheduler-edit-dialog-field-emsiotify {
   line-height: 35px;
   padding-top: 6px;
   padding-bottom: 6px;
 }
 .jqx-scheduler-edit-dialog-label-rtl-emsiotify {
   line-height: 35px;
   padding-top: 6px;
   padding-bottom: 6px;
 }
 .jqx-scheduler-edit-dialog-field-rtl-emsiotify {
   line-height: 35px;
   padding-top: 6px;
   padding-bottom: 6px;
 }
 
 /*applied to a list item when the item is selected.*/ 
 .jqx-input-popup-emsiotify .jqx-fill-state-hover-emsiotify,
 .jqx-input-popup-emsiotify .jqx-fill-state-pressed-emsiotify {
     color: var(--jqx-hovered-state-content-text-color-emsiotify) !important;
     border-color: var(--jqx-hovered-state-content-background-color-emsiotify);
     text-decoration: none;
     background-color: var(--jqx-hovered-state-content-background-color-emsiotify);
     background-repeat: repeat-x;
     outline: 0;
     background: var(--jqx-hovered-state-content-background-color-emsiotify); /* Old browsers */
     -webkit-box-shadow: none;
     -moz-box-shadow: none;
     box-shadow: none;
     background-position: 0 0;
 }
 
 .jqx-tree-item-hover-emsiotify {
     color: var(--jqx-treeview-hovered-tree-item-color-emsiotify) !important;
     border-color: var(--jqx-treeview-hovered-tree-item-background-color-emsiotify);
     background-color: var(--jqx-treeview-hovered-tree-item-background-color-emsiotify);
     background: var(--jqx-treeview-hovered-tree-item-background-color-emsiotify); /* Old browsers */
 }
 
 .jqx-menu-item-hover-emsiotify, .jqx-menu-vertical-emsiotify .jqx-menu-item-top-hover-emsiotify,
 .jqx-menu-item-top-emsiotify.jqx-fill-state-hover-emsiotify {
     color: var(--jqx-menu-hovered-item-color-emsiotify);
     background-color: var(--jqx-menu-hovered-item-background-color-emsiotify);
     border-color: var(--jqx-menu-hovered-item-background-color-emsiotify);
 }
 
 .jqx-listitem-state-hover-emsiotify {
     color: var(--jqx-list-hovered-item-state-text-color-emsiotify) !important;
     border-color: var(--jqx-list-hovered-item-state-background-color-emsiotify);
     background-color: var(--jqx-list-hovered-item-state-background-color-emsiotify);
 }
 
 .jqx-grid-cell-hover-emsiotify {
     color: var(--jqx-grid-hovered-row-text-color-emsiotify) !important;
     background-color: var(--jqx-grid-hovered-row-background-color-emsiotify) !important;
 }
 
 .jqx-scheduler-emsiotify {
     border-color: var(--jqx-scheduler-header-border-color-emsiotify);
 }
 
 .jqx-scheduler-emsiotify .jqx-scrollbar-state-normal-emsiotify {
     border-left-color: var(--jqx-scheduler-header-border-color-emsiotify);
 }
 
 .jqx-scheduler-emsiotify .jqx-widget-header-emsiotify{
     color: var(--jqx-scheduler-header-text-color-emsiotify) !important;
     background-color: var(--jqx-scheduler-header-background-color-emsiotify) !important;
     border-color: var(--jqx-scheduler-header-border-color-emsiotify) !important;
 }
 
 .jqx-scheduler-all-day-cell-emsiotify {
     border-color: var(--jqx-scheduler-header-border-color-emsiotify) !important;
 }
 
 .jqx-scheduler-toolbar-emsiotify .jqx-datetimeinput-emsiotify {
     border-color: var(--jqx-scheduler-header-border-color-emsiotify) !important;
 }
 
 .jqx-scheduler-toolbar-emsiotify .jqx-group-button-normal-emsiotify {
     color: var(--jqx-scheduler-header-buttons-text-color-emsiotify);
     border-color: var(--jqx-scheduler-header-buttons-background-color-emsiotify);
     background: var(--jqx-scheduler-header-buttons-background-color-emsiotify);
 }
 .jqx-scheduler-toolbar-emsiotify .jqx-group-button-normal-emsiotify.jqx-fill-state-hover-emsiotify {
     color: var(--jqx-scheduler-header-buttons-hovered-text-color-emsiotify);
     background: var(--jqx-scheduler-header-buttons-hovered-background-color-emsiotify);
 }
 .jqx-scheduler-toolbar-emsiotify .jqx-group-button-normal-emsiotify.jqx-fill-state-pressed-emsiotify {
     color: var(--jqx-scheduler-header-buttons-selected-text-color-emsiotify) !important;
     border-color: var(--jqx-scheduler-header-buttons-selected-background-color-emsiotify) !important;
     background-color: var(--jqx-scheduler-header-buttons-selected-background-color-emsiotify) !important;
 }
 
 .jqx-scheduler-work-time-cell-emsiotify, .jqx-scheduler-not-work-time-cell-emsiotify {
     border-color: var(--jqx-scheduler-border-color-emsiotify) !important; 
 }
 
 .jqx-scheduler-not-work-time-cell-emsiotify {
     background-color: var(--jqx-scheduler-not-work-time-cells-color-emsiotify);
 }
 
 .jqx-scheduler-cell-hover-emsiotify {
     border-color: var(--jqx-scheduler-hovered-cell-border-color-emsiotify) !important;
     background: var(--jqx-scheduler-hovered-cell-background-color-emsiotify) !important;
 }
 
 .jqx-scheduler-toolbar-emsiotify .jqx-datetimeinput {
     background-color: inherit !important;
 }
 .jqx-scheduler-toolbar-emsiotify .jqx-datetimeinput .jqx-action-button-emsiotify{
     background-color: inherit !important;
 }
 
 .jqx-scheduler-toolbar-emsiotify .jqx-icon-arrow-left-emsiotify {
     background-image: var(--jqx-scheduler-weeks-arrow-left-color-emsiotify);
 }
 .jqx-scheduler-toolbar-emsiotify .jqx-icon-arrow-right-emsiotify {
     background-image: var(--jqx-scheduler-weeks-arrow-right-color-emsiotify);
 }
 .jqx-scheduler-toolbar-emsiotify .jqx-icon-arrow-left-selected-emsiotify {
     background-image: var(--jqx-scheduler-weeks-arrow-left-selected-color-emsiotify);
 }
 .jqx-scheduler-toolbar-emsiotify .jqx-icon-arrow-right-selected-emsiotify {
     background-image: var(--jqx-scheduler-weeks-arrow-right-selected-color-emsiotify);
 }
 
 .jqx-scheduler-toolbar-emsiotify .jqx-icon-calendar {
     background-image: var(--jqx-scheduler-calendar-icon-color-emsiotify);
 }
 .jqx-scheduler-toolbar-emsiotify .jqx-icon-calendar-pressed {
     background-image: var(--jqx-scheduler-calendar-icon-selected-color-emsiotify);
 }
 
 
 .jqx-grid-selectionarea-emsiotify,
 .jqx-input-button-header-emsiotify, .jqx-input-button-innerHeader-emsiotify {
     color: var(--jqx-accent-color-emsiotify) !important;
     border-color: #E1F5FE !important;
     background: #E1F5FE; /* Old browsers */
     box-shadow: none;
 }
 
 .jqx-tree-item-selected-emsiotify {
     color: var(--jqx-treeview-selected-tree-item-color-emsiotify) !important;
     border-color: var(--jqx-treeview-selected-tree-item-background-color-emsiotify) !important;
     background: var(--jqx-treeview-selected-tree-item-background-color-emsiotify); /* Old browsers */
     box-shadow: none;
 }
 
 .jqx-menu-item-selected-emsiotify, .jqx-menu-vertical-emsiotify .jqx-menu-item-top-selected-emsiotify, .jqx-menu-item-top-selected-emsiotify {
     color: var(--jqx-menu-selected-item-color-emsiotify) !important;
     border-color: var(--jqx-menu-selected-item-border-color-emsiotify) !important;
     background-color: var(--jqx-menu-selected-item-background-color-emsiotify) !important;
     background: var(--jqx-menu-selected-item-background-color-emsiotify); /* Old browsers */
 }
 
 .jqx-listitem-state-selected-emsiotify, .jqx-listmenu-item-emsiotify.jqx-fill-state-pressed-emsiotify {
     color: var(--jqx-list-selected-item-state-text-color-emsiotify) !important;
     border-color: var(--jqx-list-selected-item-state-background-color-emsiotify) !important;
     background: var(--jqx-list-selected-item-state-background-color-emsiotify); /* Old browsers */
 }
 
 .jqx-grid-cell-selected-emsiotify {
     color: var(--jqx-grid-selected-row-text-color-emsiotify) !important;
     border-color: var(--jqx-grid-selected-row-border-color-emsiotify) !important;
     background-color: var(--jqx-grid-selected-row-background-color-emsiotify) !important;
     background: var(--jqx-grid-selected-row-background-color-emsiotify); /* Old browsers */
     box-shadow: none;
 }
 
 .jqx-scheduler-cell-selected-emsiotify {
     border-color: var(--jqx-scheduler-selected-cell-border-color-emsiotify) !important;
     background-color: var(--jqx-scheduler-selected-cell-background-color-emsiotify) !important;
 }
 
 .jqx-grid-column-header-emsiotify .sorticon {
     background-color: inherit !important;
 }
 .jqx-grid-column-header-emsiotify .jqx-widget-header-emsiotify {
     background-color: inherit !important;
 }
 .jqx-widget-header-emsiotify.sortasc, .jqx-grid-column-sortascbutton-emsiotify,
 .jqx-widget-header-emsiotify.sortdesc, .jqx-grid-column-sortdescbutton-emsiotify {
     background-color: inherit !important;
 }
 
 .jqx-grid-cell-emsiotify .jqx-button-emsiotify, .jqx-grid-cell-emsiotify .jqx-button-emsiotify.jqx-fill-state-hover-emsiotify, .jqx-grid-cell-emsiotify .jqx-button-emsiotify.jqx-fill-state-pressed-emsiotify {
     -webkit-box-shadow: none;
     -moz-box-shadow: none;
     box-shadow: none;
     -webkit-transition: none;
     -moz-transition: none;
     -o-transition: none;
     transition: none;
 }
 
 .jqx-grid-cell-selected.jqx-grid-cell-edit-emsiotify input {
     background-color: var(--jqx-grid-editor-background-color-emsiotify) !important;
     color: var(--jqx-grid-editor-text-color-emsiotify) !important;
 }
 
 #tabletreegrid .jqx-cell-editor-emsiotify {
     background-color: var(--jqx-grid-editor-background-color-emsiotify) !important;
     color: var(--jqx-grid-editor-text-color-emsiotify) !important;
 }
 
 .jqx-popup-emsiotify {
     border: 1px solid var(--jqx-border-color-emsiotify);
     *border-right-width: 2px;
     *border-bottom-width: 2px;
     -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
     -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
 }
 
 .jqx-grid-column-sortascbutton-emsiotify, .jqx-expander-arrow-bottom-emsiotify, .jqx-window-collapse-button-emsiotify, .jqx-menu-item-arrow-up-emsiotify, .jqx-menu-item-arrow-up-selected-emsiotify, .jqx-menu-item-arrow-top-up-emsiotify, .jqx-icon-arrow-up-emsiotify, .jqx-icon-arrow-up-hover-emsiotify, .jqx-icon-arrow-up-selected-emsiotify {
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-up.png');
     background-repeat: no-repeat;
     background-position: center;
 }
 
 .jqx-widget-emsiotify .jqx-grid-group-expand-emsiotify, .jqx-grid-group-expand-emsiotify, .jqx-grid-column-menubutton-emsiotify, .jqx-grid-column-sortdescbutton-emsiotify, .jqx-expander-arrow-top-emsiotify, .jqx-window-collapse-button-collapsed-emsiotify, .jqx-menu-item-arrow-down-emsiotify, .jqx-menu-item-arrow-down-selected-emsiotify, .jqx-menu-item-arrow-down-emsiotify, .jqx-icon-arrow-down-emsiotify {
     background-image: var(--jqx-arrow-down-color-emsiotify); 
     background-repeat: no-repeat;
     background-position: center;
 }
 
 .jqx-icon-arrow-down-hover-emsiotify {
     background-image: var(--jqx-arrow-down-hovered-color-emsiotify);
 }
 
 .jqx-icon-arrow-down-selected-emsiotify {
     background-image: var(--jqx-arrow-down-selected-color-emsiotify);
 }
 
 .jqx-icon-arrow-left-emsiotify, .jqx-icon-arrow-down-left-emsiotify, .jqx-icon-arrow-left-selected-emsiotify {
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-left.png');
     background-repeat: no-repeat;
     background-position: center;
 }
 
 .jqx-tabs-arrow-left-emsiotify {
     background-image: var(--jqx-tabs-arrow-left-color-emsiotify);
     background-repeat: no-repeat;
     background-position: center;
 }
 
 .jqx-tabs-arrow-right-emsiotify {
     background-image: var(--jqx-tabs-arrow-right-color-emsiotify);
     background-repeat: no-repeat;
     background-position: center;
 }
 
 .jqx-widget-emsiotify .jqx-grid-group-collapse-emsiotify, .jqx-grid-group-collapse-emsiotify, .jqx-icon-arrow-right-emsiotify, .jqx-icon-arrow-right-hover-emsiotify, .jqx-icon-arrow-right-selected-emsiotify {
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-right.png');
     background-repeat: no-repeat;
     background-position: center;
 }
 
 .jqx-menu-item-arrow-top-left-emsiotify {
     background-image: var(--jqx-menu-arrow-left-color-emsiotify);
     background-repeat: no-repeat;
     background-position: center;
 }
 .jqx-menu-item-arrow-top-right-emsiotify {
     background-image: var(--jqx-menu-arrow-right-color-emsiotify);
     background-repeat: no-repeat;
     background-position: center;
 }
 
 
 
 .jqx-grid-cell-hover-emsiotify .jqx-grid-group-expand-emsiotify.jqx-icon-arrow-down-emsiotify {
     background-image: var(--jqx-grid-arrow-down-hovered-color-emsiotify);
 }
 .jqx-grid-cell-hover-emsiotify .jqx-grid-group-expand-rtl-emsiotify.jqx-icon-arrow-down-emsiotify {
     background-image: var(--jqx-grid-arrow-down-hovered-color-emsiotify);
 }
 .jqx-grid-cell-hover-emsiotify .jqx-grid-group-collapse-emsiotify.jqx-icon-arrow-right-emsiotify {
     background-image: var(--jqx-grid-arrow-rigth-hovered-color-emsiotify);
 }
 .jqx-grid-cell-hover-emsiotify .jqx-grid-group-collapse-rtl-emsiotify.jqx-icon-arrow-left-emsiotify {
     background-image: var(--jqx-grid-arrow-left-hovered-color-emsiotify);
 }
 
 .jqx-grid-group-collapse-emsiotify.jqx-icon-arrow-right-emsiotify {
     background-image: var(--jqx-grid-groups-arrow-right-color-emsiotify);
 }
  .jqx-grid-group-expand-emsiotify.jqx-icon-arrow-down-emsiotify {
     background-image: var(--jqx-grid-groups-arrow-down-color-emsiotify);
 }
 
 .jqx-grid-group-collapse-rtl-emsiotify.jqx-icon-arrow-left-emsiotify {
     background-image: var(--jqx-grid-groups-arrow-left-color-emsiotify);
 }
 .jqx-grid-group-expand-rtl-emsiotify.jqx-icon-arrow-down-emsiotify {
     background-image: var(--jqx-grid-groups-arrow-down-color-emsiotify);
 } 
 
 .jqx-tree-grid-collapse-button-emsiotify.jqx-icon-arrow-right-emsiotify{
     background-image: var(--jqx-grid-arrow-right-color-emsiotify);
 }
 .jqx-tree-grid-collapse-button-emsiotify.jqx-icon-arrow-left-emsiotify{
     background-image: var(--jqx-grid-arrow-left-color-emsiotify);
 }
 .jqx-tree-grid-expand-button-emsiotify.jqx-icon-arrow-down-emsiotify {
     background-image: var(--jqx-grid-arrow-down-color-emsiotify);
 }
 
 .jqx-grid-pager-emsiotify .jqx-icon-arrow-left-emsiotify {
     background-image: var(--jqx-grid-arrow-left-color-emsiotify);
 }
 .jqx-grid-pager-emsiotify .jqx-icon-arrow-left-hover-emsiotify {
     background-image: var(--jqx-grid-arrow-left-hovered-color-emsiotify);
 }
 .jqx-grid-pager-emsiotify .jqx-icon-arrow-left-selected-emsiotify {
     background-image: var(--jqx-grid-arrow-left-selected-color-emsiotify);
 }
 .jqx-grid-pager-emsiotify .jqx-icon-arrow-right-emsiotify {
     background-image: var(--jqx-grid-arrow-right-color-emsiotify);
 }
 .jqx-grid-pager-emsiotify .jqx-icon-arrow-right-hover-emsiotify {
     background-image: var(--jqx-grid-arrow-right-hovered-color-emsiotify);
 }
 .jqx-grid-pager-emsiotify .jqx-icon-arrow-right-selected-emsiotify {
     background-image: var(--jqx-grid-arrow-right-selected-color-emsiotify);
 }
 
 .jqx-grid-column-sorticon-emsiotify.jqx-icon-arrow-down-emsiotify {
     background-image: var(--jqx-grid-header-arrow-down-color-emsiotify);
 }
 .jqx-grid-column-sortdescbutton-emsiotify {
     background-image: var(--jqx-grid-header-arrow-down-color-emsiotify);
 }
 .jqx-grid-column-sortascbutton-emsiotify {
     background-image: var(--jqx-grid-header-arrow-up-color-emsiotify);
 }
 .jqx-grid-column-menubutton-emsiotify {
     background-image: var(--jqx-grid-menu-button-color-emsiotify) !important;
 }
 
 .jqx-grid-group-column-emsiotify .jqx-grid-column-sortdescbutton-emsiotify {
     background-image: var(--jqx-grid-arrow-down-color-emsiotify);
 }
 
 .jqx-tree-item-arrow-collapse-rtl-emsiotify, .jqx-tree-item-arrow-collapse-hover-rtl-emsiotify {
     background-image: url(../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-left.png);
 }
 
 .jqx-menu-item-arrow-left-selected-emsiotify {
     background-image: var(--jqx-menu-arrow-left-selected-color-emsiotify);
     background-repeat: no-repeat;
     background-position: center;
 }
 
 .jqx-menu-item-arrow-right-selected-emsiotify {
     background-image: var(--jqx-menu-arrow-right-selected-color-emsiotify);
     background-repeat: no-repeat;
     background-position: center;
 }
 
 .jqx-tree-item-arrow-expand-emsiotify {
     background-image: var(--jqx-treeview-arrow-expanded-color-emsiotify) !important;
 }
 
 .jqx-tree-item-arrow-collapse-emsiotify
 {
     transform: rotate(-90deg);
     background-image: var(--jqx-treeview-arrow-collapsed-color-emsiotify);
     background-repeat: no-repeat;
     background-position: center;
     transition: transform 0.2s ease-out;
 }
 
 .jqx-navbar-emsiotify {
     border-color: var(--jqx-navbar-item-background-color-emsiotify) !important;
 }
 
 .jqx-navbar-block-emsiotify {
     color: var(--jqx-navbar-item-text-color-emsiotify) !important;
     background: var(--jqx-navbar-item-background-color-emsiotify) !important;
     border-color: var(--jqx-navbar-item-background-color-emsiotify) !important;
 }
 
 .jqx-navbar-block-emsiotify.jqx-fill-state-hover-emsiotify {
     color: var(--jqx-navbar-hovered-item-text-color-emsiotify) !important;
     background-color: var(--jqx-navbar-hovered-item-background-color-emsiotify) !important;
     border-color: var(--jqx-navbar-hovered-item-background-color-emsiotify) !important;
     opacity: 1
 }
 
 .jqx-navbar-block-emsiotify.jqx-fill-state-pressed-emsiotify {
     color: var(--jqx-navbar-selected-item-text-color-emsiotify) !important;
     background-color: var(--jqx-navbar-selected-item-background-color-emsiotify) !important;
     border-color: var(--jqx-navbar-selected-item-background-color-emsiotify) !important;
 }
 
 .jqx-input-button-content-emsiotify {
     font-size: 10px;
 }
 
 .jqx-widget .jqx-grid-column-header-cell-emsiotify {
     padding-top: 8px;
     padding-bottom: 8px;
     height:30px;
 }
 
 .jqx-widget .jqx-grid-row-cell-emsiotify {
     padding-top: 8px;
     padding-bottom: 8px;
     height:30px;
 }
 
 .jqx-widget .jqx-grid-cell-emsiotify, .jqx-widget .jqx-grid-column-header-emsiotify, .jqx-widget .jqx-grid-group-cell-emsiotify {
     border-color: var(--jqx-border-color-emsiotify);
 }
 
 .jqx-input-emsiotify, .jqx-input-emsiotify input, .jqx-maskedinput-emsiotify {
     border-radius: var(--jqx-editors-text-editors-border-radius-emsiotify) !important;
 }
 
 .jqx-combobox-emsiotify .jqx-icon-close-emsiotify {
     background-image:url(../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/close_white.png)
 }
 .jqx-combobox-emsiotify, .jqx-input-emsiotify {
     border-color: var(--jqx-editors-text-editors-border-color-emsiotify);
     color: var(--jqx-editors-text-editors-text-color-emsiotify);
     background-color: var(--jqx-editors-text-editors-background-color-emsiotify);
     border-left: none;
     border-right: none;
     border-top: none;
     box-shadow: none;
     -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
     -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
 }
 
 .jqx-combobox-content-emsiotify,
 .jqx-datetimeinput-content-emsiotify
  {
     border-color: transparent;
 }
 .jqx-combobox-arrow-normal-emsiotify {
     background: var(--jqx-background-color-emsiotify);
     border-color: transparent;
 }
 
 .jqx-combobox-content-focus-emsiotify, 
 .jqx-combobox-state-focus-emsiotify,
 .jqx-numberinput-focus-emsiotify {
     outline: none;
 }
 
 .jqx-combobox-emsiotify, .jqx-input-emsiotify {
     box-shadow: none;
 }
 
 .jqx-input-group-emsiotify {
     position: relative;
     display: inline-block;
     overflow: visible;
     border: none;
     box-shadow: none;
 }
 
     .jqx-input-group-emsiotify input {
         width: 100%;
         height: 100%;
         box-sizing: border-box;
     }
     .jqx-input-group-emsiotify textarea {
         width: 100%;
         height: 100%;
         outline: none;
         resize: none;
         border-left: none;
         border-right: none;
         border-top: none;
         border-bottom-color: var(--jqx-border-color-emsiotify);
     }
 .jqx-numberinput-emsiotify,
 .jqx-maskedinput-emsiotify
  {
     position:relative;
 }
 .jqx-numberinput-emsiotify input {
     height:100% !important;
 }
 
 .jqx-input-emsiotify.jqx-validator-error-element {
     border-color: transparent !important;
     border-bottom: 1px solid #df2227 !important; 
 }
 .jqx-input-emsiotify input,
 .jqx-dropdownlist-state-normal-emsiotify,
 .jqx-combobox-state-normal-emsiotify,
 .jqx-datetimeinput-emsiotify,
 .jqx-numberinput-emsiotify,
 .jqx-maskedinput-emsiotify
  {
     background: var(--jqx-editors-text-editors-background-color-emsiotify);
     color: var(--jqx-editors-text-editors-text-color-emsiotify);
     border-color: #fff;
     border-radius: 0;
     box-shadow: none;
     border-bottom: 1px solid var(--jqx-editors-text-editors-border-color-emsiotify);
     outline: none;
 }
 .jqx-datetimeinput-emsiotify .jqx-action-button-emsiotify,
 .jqx-datetimeinput-emsiotify .jqx-action-button-rtl-emsiotify 
  {
     background-color: transparent;
     border-color: transparent;
 }
     .jqx-datetimeinput-emsiotify, .jqx-datetimeinput-emsiotify > div,
     .jqx-numberinput-emsiotify, .jqx-numberinput-emsiotify > div,
     .jqx-maskedinput-emsiotify, .jqx-maskedinput-emsiotify > div,
     .jqx-dropdownlist-state-normal-emsiotify, .jqx-dropdownlist-state-normal-emsiotify > div, .jqx-dropdownlist-state-normal-emsiotify > div > div,
     .jqx-combobox-state-normal-emsiotify, .jqx-combobox-state-normal-emsiotify > div, .jqx-combobox-state-normal-emsiotify > div > div {
         overflow: visible !important;
     }
 
     .jqx-input-emsiotify input:focus {
         border-radius: 0;
         box-shadow: none;
     }
 
 .jqx-input-emsiotify input, input[type="text"].jqx-input-emsiotify, input[type="password"].jqx-input-emsiotify, input[type="text"].jqx-widget-content-emsiotify, input[type="textarea"].jqx-widget-content-emsiotify, textarea.jqx-input-emsiotify {
     font-size: 14px;
     resize: none;
     background: var(--jqx-editors-text-editors-background-color-emsiotify);
     color: var(--jqx-editors-text-editors-text-color-emsiotify);
     border: none;
     border-radius: 0;
     box-sizing:border-box;
     box-shadow: none;
     border-bottom: 1px solid var(--jqx-editors-text-editors-border-color-emsiotify);
 }
 .jqx-input-label-emsiotify {
     visibility:inherit;
 }
 .jqx-input-bar-emsiotify{
     visibility:inherit;
 }
 input:focus ~ .jqx-input-label-emsiotify,
 textarea:focus ~ .jqx-input-label-emsiotify,
 .jqx-input-widget-emsiotify[hint=true] .jqx-input-label,
 .jqx-text-area-emsiotify[hint=true] .jqx-input-label,
 .jqx-dropdownlist-state-selected-emsiotify .jqx-input-label,
 .jqx-dropdownlist-state-normal-emsiotify[hint=true] .jqx-input-label,
 .jqx-combobox-state-normal-emsiotify[hint=true] .jqx-input-label,
 .jqx-combobox-emsiotify .jqx-input-label.focused,
 .jqx-dropdownlist-emsiotify .jqx-input-label.focused,
 .jqx-datetimeinput-emsiotify[hint=true] .jqx-input-label,
 .jqx-maskedinput-emsiotify[hint=true] .jqx-input-label,
 .jqx-numberinput-emsiotify[hint=true] .jqx-input-label,
 .jqx-formattedinput-emsiotify[hint=true] .jqx-input-label
  {
     top: -15px;
     font-size: 12px;
     color: var(--jqx-editors-text-editors-focused-border-color-emsiotify);
 }
 
 .jqx-combobox-emsiotify #dropdownlistContentcombobox {
     background-color: var(--jqx-editors-text-editors-background-color-emsiotify);
 }
 .jqx-dropdownlist-emsiotify[default-placeholder="true"] .jqx-input-label {
     visibility: hidden;
 }
 
 
 input:focus ~ .jqx-input-bar:before,
 textarea:focus ~ .jqx-input-bar:before,
 .jqx-dropdownlist-state-selected-emsiotify .jqx-input-bar:before,
 .jqx-dropdownlist-emsiotify .jqx-input-bar.focused:before,
 .jqx-combobox-emsiotify .jqx-input-bar.focused:before,
 .jqx-complex-input-group-emsiotify .jqx-input-bar.focused::before,
 .jqx-combobox-state-selected-emsiotify .jqx-input-bar:before {
     width: 100%;
 }
 .jqx-complex-input-group-emsiotify .jqx-fill-state-normal-emsiotify {
     border-color: #fafafa;
 }
 .jqx-input-widget-emsiotify input[type="password"]{
     letter-spacing: 0.3em;
 }
 
 .jqx-input-label-emsiotify {
     color: var(--jqx-border-color-emsiotify);
     font-size: 14px;
     font-weight: normal;
     position: absolute;
     pointer-events: none;
     left: 2px;
     top:10px;
     top: calc(50% - 7px);
     transition: 300ms ease all;
 }
 .jqx-input-label.initial {
     transition: none;
 }
 .jqx-input-bar-emsiotify {
     position: relative;
     display: block;
     z-index:1;
 }
 
     .jqx-input-bar-emsiotify:before {
         content: '';
         height: 2px;
         width: 0;
         bottom: 0px;
         position: absolute;
         background: var(--jqx-editors-text-editors-focused-border-color-emsiotify);
         transition: 300ms ease all;
         left: 0%;
     }
 .jqx-formatted-input-spin-button-emsiotify, .jqx-input-group-addon-emsiotify {
     border-color: #fff;
     background: #fff;
 }
 .jqx-dropdownlist-state-selected-emsiotify,
 .jqx-combobox-state-selected-emsiotify {
     color: var(--jqx-accent-color-emsiotify);
 }
 
 .jqx-switchbutton-thumb-emsiotify {
     border-color: var(--jqx-editors-switch-thumb-color-emsiotify);
     background: var(--jqx-background-color-emsiotify);
 }
 
 .jqx-switchbutton-label-on-emsiotify {
     color: var(--jqx-editors-switch-on-label-text-color-emsiotify);
     background-color: var(--jqx-editors-switch-on-label-background-color-emsiotify);
 }
 
 .jqx-switchbutton-label-off-emsiotify {
     color: var(--jqx-editors-switch-off-label-text-color-emsiotify);
     background-color: var(--jqx-editors-switch-off-label-background-color-emsiotify);
 }
 
 .jqx-file-upload-emsiotify, .jqx-file-upload-file-row-emsiotify {
     background-color: var(--jqx-editors-file-uploader-background-color-emsiotify);
 }
 .jqx-file-upload-file-name-emsiotify {
     color: var(--jqx-editors-file-uploader-filename-text-color-emsiotify) !important;
 }
 
 .jqx-dropdownlist-state-normal-emsiotify .jqx-icon-arrow-down-emsiotify,
 .jqx-combobox-state-normal-emsiotify .jqx-icon-arrow-down-emsiotify,
 .sorticon.descending .jqx-grid-column-sorticon-emsiotify,
 .jqx-tree-item-arrow-expand-emsiotify,
  .jqx-expander-header-emsiotify .jqx-icon-arrow-down
  {
     transform: rotate(0deg);
     transition: transform 0.2s ease-out;
 }
 
 .jqx-dropdownlist-state-hover-emsiotify {
     background-color: var(--jqx-hovered-state-background-color-emsiotify);
     color: var(--jqx-hovered-state-text-color-emsiotify);
 }
 
 .jqx-expander-header-emsiotify .jqx-icon-arrow-up {
    transform: rotate(180deg);
    transition: transform 0.2s ease-out;
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-down.png');
 }
 
 .jqx-expander-arrow-top-emsiotify {
     background-image: var(--jqx-accordion-arrow-down-color-emsiotify);
 }
 
 .jqx-expander-arrow-top-hover-emsiotify {
     background-image: var(--jqx-accordion-arrow-down-hovered-color-emsiotify) !important;
 }
 
 .jqx-expander-arrow-expanded-emsiotify {
     background-image: var(--jqx-accordion-arrow-down-selected-color-emsiotify) !important;
 }
 
 .jqx-dropdownlist-state-selected-emsiotify .jqx-icon-arrow-down-emsiotify,
 .jqx-combobox-state-selected-emsiotify .jqx-icon-arrow-down-emsiotify,
 .sorticon.ascending .jqx-grid-column-sorticon-emsiotify
  {
     transform: rotate(180deg);
     transition: transform 0.2s ease-out;
     left: -1px;
 }
 .jqx-combobox-state-selected-emsiotify .jqx-icon-arrow-down-emsiotify{
     left:-1px;
 }
 .jqx-listbox-container {
     margin-top: 1px;
 }
 
 input[type="text"].jqx-input-emsiotify:-moz-placeholder, input[type="text"].jqx-widget-content-emsiotify:-moz-placeholder, input[type="textarea"].jqx-widget-content-emsiotify:-moz-placeholder, textarea.jqx-input-emsiotify:-moz-placeholder {
     color: #999999;
 }
 
 input[type="text"].jqx-input-emsiotify:-webkit-input-placeholder, input[type="text"].jqx-widget-content-emsiotify:-webkit-input-placeholder, input[type="textarea"].jqx-widget-content-emsiotify:-webkit-input-placeholder, textarea.jqx-input-emsiotify:-webkit-input-placeholder {
     color: #999999;
 }
 
 input[type="text"].jqx-input-emsiotify:-ms-input-placeholder, input[type="text"].jqx-widget-content-emsiotify:-ms-input-placeholder, input[type="textarea"].jqx-widget-content-emsiotify:-ms-input-placeholder, textarea.jqx-input-emsiotify:-ms-input-placeholder {
     color: #999999;
 }
 
 .jqx-combobox-content-focus-emsiotify, .jqx-combobox-state-focus-emsiotify, .jqx-fill-state-focus-emsiotify,
 .jqx-numberinput-focus-emsiotify {
     outline: none;
 }
 
 .jqx-combobox-multi-item-emsiotify {
     color: var(--jqx-editors-combobox-multi-item-text-color-emsiotify);
     background: var(--jqx-editors-combobox-multi-item-background-color-emsiotify);
     border-color: var(--jqx-editors-combobox-multi-item-background-color-emsiotify);
 }
 
 .jqx-popup-emsiotify.jqx-fill-state-focus-emsiotify {
     outline: none;
     border-color: #E0E0E0 !important;
 }
 
 .jqx-datetimeinput-content, .jqx-datetimeinput-container {
     overflow: visible !important;
 }
 .jqx-text-area-emsiotify, .jqx-text-area-emsiotify > div {
     overflow:visible !important;
 }
 .jqx-text-area-element-emsiotify {
    box-sizing: border-box;
 }
 .jqx-pivotgrid-content-wrapper.jqx-fill-state-normal-emsiotify {
     border-color: var(--jqx-border-color-emsiotify);
 }
 
 .jqx-widget-header-emsiotify.jqx-pivotgrid-content-wrapper {
     background-color: var(--jqx-pivotgrid-header-background-color-emsiotify) !important;
     color: var(--jqx-pivotgrid-header-text-color-emsiotify) !important;
 }
 
 .jqx-grid-cell-normal-emsiotify.jqx-pivotgrid-content-wrapper {
     background-color: var(--jqx-pivotgrid-cell-background-color-emsiotify);
     color: var(--jqx-pivotgrid-cell-text-color-emsiotify);
 }
 
 .jqx-grid-cell-selected-emsiotify.jqx-pivotgrid-content-wrapper {
     color: var(--jqx-pivotgrid-selected-cell-text-color-emsiotify) !important;
     background-color: var(--jqx-pivotgrid-selected-cell-background-color-emsiotify) !important;
     border-color: var(--jqx-pivotgrid-selected-cell-background-color-emsiotify) !important;
 }
 
 .jqx-tabs-title-selected-top-emsiotify, .jqx-tabs-selection-tracker-top-emsiotify {
     border-color: transparent;
     filter: none;
     background: var(--jqx-tabs-selected-tab-background-color-emsiotify);
     -webkit-box-shadow: none;
     -moz-box-shadow: none;
     box-shadow: none;
 }
 .jqx-grid-cell-filter-row-emsiotify {
     background-color: #fafafa;
 }
 
 .jqx-tabs-title-emsiotify, .jqx-ribbon-item-emsiotify {
     color: var(--jqx-tabs-tab-text-color-emsiotify);
 }
 .jqx-tabs-title-selected-bottom-emsiotify,
 .jqx-tabs-title-selected-top-emsiotify
  {
     color: var(--jqx-tabs-selected-tab-text-color-emsiotify);
     font-weight:500;   
     padding-top:5px;
     padding-bottom:5px;
 }
 .jqx-tabs-title.jqx-fill-state-hover-emsiotify {
     border-color: transparent;
 }
 .jqx-ribbon-item-emsiotify {
     cursor: pointer;
 }
 .jqx-ribbon-item-selected-emsiotify {
     color: var(--jqx-tabs-selected-tab-text-color-emsiotify);
     font-weight:500;
     border-color: transparent;
 }
 
 .jqx-ribbon-item-hover-emsiotify {
     background: transparent;
 }
 
 .jqx-ribbon-header-top-emsiotify {
     border-color: transparent;
     border-bottom-color: var(--jqx-border-color-emsiotify);
 }
 
 .jqx-ribbon-header-bottom-emsiotify {
     border-color: transparent;
     border-top-color: var(--jqx-border-color-emsiotify);
 }
 
 .jqx-ribbon-header-right-emsiotify {
     border-color: transparent;
     border-left-color:var(--jqx-border-color-emsiotify);
 }
 
 .jqx-ribbon-header-left-emsiotify {
     border-color: transparent;
     border-right-color:var(--jqx-border-color-emsiotify);
 }
 
 .jqx-tabs-title-selected-bottom-emsiotify, .jqx-tabs-selection-tracker-bottom-emsiotify {
     border-color: transparent;
     border-top: 1px solid #fff;
     filter: none;
     background: var(--jqx-tabs-selected-tab-background-color-emsiotify);
     -webkit-box-shadow: none;
     -moz-box-shadow: none;
     box-shadow: none;
 }
 
 .jqx-tabs-emsiotify, .jqx-ribbon-emsiotify {
     border-color: transparent;
 }
 
 .jqx-tabs-position-bottom .jqx-tabs-header-emsiotify {
     border-color: transparent;
 }
 .jqx-layout-emsiotify .jqx-tabs-header-emsiotify, .jqx-layout-emsiotify .jqx-ribbon-header-emsiotify {
     background: var(--jqx-background-color-emsiotify);
     border-color: var(--jqx-border-color-emsiotify);
 }
 .jqx-tabs-title-bottom {
     border-color: transparent;
 }
 .jqx-tabs-title-hover-top-emsiotify, .jqx-tabs-title-hover-bottom-emsiotify {
     -webkit-box-shadow: none !important;
     -moz-box-shadow: none !important;
     box-shadow: none !important;
     color: var(--jqx-tabs-hovered-tab-text-color-emsiotify);
     background: var(--jqx-tabs-hovered-tab-background-color-emsiotify);
 }
 
 .jqx-tabs-header-emsiotify, .jqx-tabs-arrow-background-emsiotify {
     background-color: var(--jqx-tabs-header-background-color-emsiotify) !important;
     border-bottom: 1px solid var(--jqx-tabs-border-color-emsiotify);
 }
 
 .jqx-tabs-content-emsiotify {
     box-sizing: border-box;
     color: var(--jqx-tabs-content-text-color-emsiotify);
     background-color: var(--jqx-tabs-content-background-color-emsiotify);
     border: 1px solid var(--jqx-tabs-border-color-emsiotify);
     border-top-color: transparent;
     padding:5px;
 }
 
 .jqx-tabs-bar-emsiotify {
     position: absolute;
     bottom: 0;
     background: var(--jqx-tabs-selected-tab-bottom-border-color-emsiotify);
     height: 2px;
     z-index:10;
     transition: .5s cubic-bezier(.35,0,.25,1);
 }
 .jqx-tabs-bar-emsiotify.vertical {
     width: 2px;
 }
 .jqx-tabs-position-bottom .jqx-tabs-bar-emsiotify {
     top: 0;
 }
 
 
 .jqx-layout-emsiotify {
     background-color: #cccccc;
 }
 
 .jqx-kanban-column-header-collapsed-emsiotify {
     background: -moz-linear-gradient(0deg, rgba(248,248,248,1) 0%, rgba(234,234,234,1) 100%); /* ff3.6+ */
     background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(248,248,248,1)), color-stop(100%, rgba(234,234,234,1))); /* safari4+,chrome */
     background: -webkit-linear-gradient(0deg, rgba(248,248,248,1) 0%, rgba(234,234,234,1) 100%); /* safari5.1+,chrome10+ */
     background: -o-linear-gradient(0deg, rgba(248,248,248,1) 0%, rgba(234,234,234,1) 100%); /* opera 11.10+ */
     background: -ms-linear-gradient(0deg, rgba(248,248,248,1) 0%, rgba(234,234,234,1) 100%); /* ie10+ */
     background: linear-gradient(90deg, rgba(248,248,248,1) 0%, rgba(234,234,234,1) 100%); /* w3c */
 }
 
 
 .jqx-calendar-cell-emsiotify {
     border-radius: 50%;
     font-size:12px;
 }
 .jqx-calendar-cell-year-emsiotify,
 .jqx-calendar-cell-decade-emsiotify {
     border-radius: 25%;
 }
 
 .jqx-calendar-title-content-emsiotify {
     font-weight:bold;
 }
 
 .jqx-calendar-title-navigation-emsiotify.jqx-icon-arrow-left-emsiotify {
     background-image: var(--jqx-editors-calendar-arrow-left-color-emsiotify);
 }
 
 .jqx-calendar-title-navigation-emsiotify.jqx-icon-arrow-right-emsiotify {
     background-image: var(--jqx-editors-calendar-arrow-right-color-emsiotify);
 }
 
 .jqx-calendar-column-cell-emsiotify {
     color: var(--jqx-editors-calendar-header-text-color-emsiotify);
     font-size:12px;
 }
 .jqx-grid-column-menubutton-emsiotify {
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-down.png');
 }
 
 .jqx-tabs-close-button-emsiotify {
     background-image: url(../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/close.png);
     background-repeat: no-repeat;
     background-position: center;
 }
 
 .jqx-tabs-close-button-selected-emsiotify {
     background-image: url(../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/close.png);
     background-repeat: no-repeat;
     background-position: center;
 }
 
 .jqx-tabs-close-button-hover-emsiotify {
     background-image: url(../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/close.png);
     background-repeat: no-repeat;
     background-position: center;
 }
 
 .jqx-grid-cell-emsiotify.jqx-grid-cell-selected-emsiotify>.jqx-grid-group-expand-emsiotify {
     background-image: var(--jqx-grid-arrow-down-selected-color-emsiotify);
     background-repeat: no-repeat;
     background-position: center;
 }
 .jqx-grid-cell-emsiotify.jqx-grid-cell-selected-emsiotify>.jqx-grid-group-collapse-emsiotify{
     background-image: var(--jqx-grid-arrow-right-selected-color-emsiotify);
     background-repeat: no-repeat;
     background-position: center;
 }
 .jqx-grid-cell-emsiotify.jqx-grid-cell-selected-emsiotify>.jqx-grid-group-collapse-rtl-emsiotify {
     background-image: var(--jqx-grid-arrow-left-selected-color-emsiotify);
     background-repeat: no-repeat;
     background-position: center;
 }
 .jqx-grid-cell-emsiotify.jqx-grid-cell-selected-emsiotify>.jqx-grid-group-expand-rtl-emsiotify{
     background-image: var(--jqx-grid-arrow-down-selected-color-emsiotify);
     background-repeat: no-repeat;
     background-position: center;
 }
 
 .jqx-grid-group-collapse-emsiotify {
     background-image: url(../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-right.png);
     background-position: 50% 50%;
     background-repeat: no-repeat;
 }
 
 .jqx-grid-group-collapse-rtl-emsiotify {
     padding-right: 0px;
     background-image: url(../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-left.png);
     background-position: 50% 50%;
     background-repeat: no-repeat;
 }
 
 .jqx-grid-group-expand-emsiotify, .jqx-grid-group-expand-rtl-emsiotify {
     padding-right: 0px;
     background-image: url(../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-down.png);
     background-position: 50% 50%;
     background-repeat: no-repeat;
 }
 
 .jqx-icon-arrow-first-emsiotify {
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-first.png');
     background-repeat: no-repeat;
     background-position: center;
 }
 
 .jqx-icon-arrow-last-emsiotify {
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-last.png');
     background-repeat: no-repeat;
     background-position: center;
 }
 
 .jqx-icon-arrow-first-hover-emsiotify {
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-first.png');
     background-repeat: no-repeat;
     background-position: center;
 }
 
 .jqx-icon-arrow-last-hover-emsiotify {
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-last.png');
     background-repeat: no-repeat;
     background-position: center;
 }
 
 .jqx-icon-arrow-first-selected-emsiotify {
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-first.png');
     background-repeat: no-repeat;
     background-position: center;
 }
 
 .jqx-icon-arrow-last-selected-emsiotify {
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-last.png');
     background-repeat: no-repeat;
     background-position: center;
 }
 
 .jqx-listmenu-arrow-right-emsiotify {
     background-image: var(--jqx-list-arrow-right-color-emsiotify);
 }
 
 .jqx-listmenu-arrow-right-pressed-emsiotify {
     background-image: var(--jqx-list-arrow-right-selected-color-emsiotify);
 }
 
 .jqx-listmenu-arrow-left-emsiotify {
     background-image: var(--jqx-list-arrow-left-color-emsiotify);
 }
 
 .jqx-listmenu-arrow-left-pressed-emsiotify {
     background-image: var(--jqx-list-arrow-left-selected-color-emsiotify);
 }
 
 .jqx-pivotgrid-item .jqx-icon-arrow-up-emsiotify {
     background-image: var(--jqx-pivotgrid-arrow-up-color-emsiotify);
 }
 .jqx-pivotgrid-item .jqx-icon-arrow-down-emsiotify {
     background-image: var(--jqx-pivotgrid-arrow-down-color-emsiotify);
 }
 .jqx-pivotgrid-menu-button-emsiotify {
     background-image: var(--jqx-pivotgrid-menu-button-color-emsiotify);
 }
 
 .jqx-primary .jqx-icon-arrow-down-emsiotify, .jqx-warning .jqx-icon-arrow-down-emsiotify, .jqx-danger .jqx-icon-arrow-down-emsiotify, .jqx-success .jqx-icon-arrow-down-emsiotify, .jqx-info .jqx-icon-arrow-down-emsiotify {
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-down.png');
 }
 
 .jqx-primary .jqx-icon-arrow-down-selected-emsiotify, .jqx-warning .jqx-icon-arrow-down-selected-emsiotify, .jqx-danger .jqx-icon-arrow-down-selected-emsiotify, .jqx-success .jqx-icon-arrow-down-selected-emsiotify, .jqx-info .jqx-icon-arrow-down-selected-emsiotify {
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-down.png');
 }
 
 .jqx-primary .jqx-icon-arrow-down-hover-emsiotify, .jqx-warning .jqx-icon-arrow-down-hover-emsiotify, .jqx-danger .jqx-icon-arrow-down-hover-emsiotify, .jqx-success .jqx-icon-arrow-down-hover-emsiotify, .jqx-info .jqx-icon-arrow-down-hover-emsiotify {
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-down.png');
 }
 
 .jqx-primary .jqx-icon-arrow-up-emsiotify, .jqx-warning .jqx-icon-arrow-up-emsiotify, .jqx-danger .jqx-icon-arrow-up-emsiotify, .jqx-success .jqx-icon-arrow-up-emsiotify, .jqx-info .jqx-icon-arrow-up-emsiotify {
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-up.png');
 }
 
 .jqx-primary .jqx-icon-arrow-up-selected-emsiotify, .jqx-warning .jqx-icon-arrow-up-selected-emsiotify, .jqx-danger .jqx-icon-arrow-up-selected-emsiotify, .jqx-success .jqx-icon-arrow-up-selected-emsiotify, .jqx-info .jqx-icon-arrow-up-selected-emsiotify {
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-up.png');
 }
 
 .jqx-primary .jqx-icon-arrow-up-hover-emsiotify, .jqx-warning .jqx-icon-arrow-up-hover-emsiotify, .jqx-danger .jqx-icon-arrow-up-hover-emsiotify, .jqx-success .jqx-icon-arrow-up-hover-emsiotify, .jqx-info .jqx-icon-arrow-up-hover-emsiotify {
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-up.png');
 }
 
 
 .jqx-primary .jqx-icon-arrow-left-emsiotify, .jqx-warning .jqx-icon-arrow-left-emsiotify, .jqx-danger .jqx-icon-arrow-left-emsiotify, .jqx-success .jqx-icon-arrow-left-emsiotify, .jqx-info .jqx-icon-arrow-left-emsiotify {
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-left.png');
 }
 
 .jqx-primary .jqx-icon-arrow-left-selected-emsiotify, .jqx-warning .jqx-icon-arrow-left-selected-emsiotify, .jqx-danger .jqx-icon-arrow-left-selected-emsiotify, .jqx-success .jqx-icon-arrow-left-selected-emsiotify, .jqx-info .jqx-icon-arrow-left-selected-emsiotify {
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-left.png');
 }
 
 .jqx-primary .jqx-icon-arrow-left-hover-emsiotify, .jqx-warning .jqx-icon-arrow-left-hover-emsiotify, .jqx-danger .jqx-icon-arrow-left-hover-emsiotify, .jqx-success .jqx-icon-arrow-left-hover-emsiotify, .jqx-info .jqx-icon-arrow-left-hover-emsiotify {
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-left.png');
 }
 
 .jqx-primary .jqx-icon-arrow-right-emsiotify, .jqx-warning .jqx-icon-arrow-right-emsiotify, .jqx-danger .jqx-icon-arrow-right-emsiotify, .jqx-success .jqx-icon-arrow-right-emsiotify, .jqx-info .jqx-icon-arrow-right-emsiotify {
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-right.png');
 }
 
 .jqx-primary .jqx-icon-arrow-right-selected-emsiotify, .jqx-warning .jqx-icon-arrow-right-selected-emsiotify, .jqx-danger .jqx-icon-arrow-right-selected-emsiotify, .jqx-success .jqx-icon-arrow-right-selected-emsiotify, .jqx-info .jqx-icon-arrow-right-selected-emsiotify {
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-right.png');
 }
 
 .jqx-primary .jqx-icon-arrow-right-hover-emsiotify, .jqx-warning .jqx-icon-arrow-right-hover-emsiotify, .jqx-danger .jqx-icon-arrow-right-hover-emsiotify, .jqx-success .jqx-icon-arrow-right-hover-emsiotify, .jqx-info .jqx-icon-arrow-right-hover-emsiotify {
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/material-icon-right.png');
 }
 
 .jqx-icon-arrow-down-hover-emsiotify {
     background-image: var(--jqx-arrow-down-hovered-color-emsiotify);
 }
 
 .jqx-icon-arrow-down-selected-emsiotify {
     background-image: var(--jqx-arrow-down-selected-color-emsiotify);
 }
 
 /* Ripple effect */
 .ripple {
     position: relative;
     transform: translate3d(0, 0, 0);
     overflow:hidden;
 }
 
 .ink {
     display: block;
     position: absolute;
     pointer-events: none;
     border-radius: 0%;
     transform: scaleX(0);
     background: rgba(0,119,190,0.5); 
     opacity: 0.25;
 }
 
 
 .outlined .ink, .flat .ink {
     background: rgba(0,119,190,0.5); 
     overflow:hidden;
 }
 
 .ink.animate {
     animation: ripple .7s ease;
     animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
 }
 .jqx-checkbox-emsiotify .ripple,
 .jqx-radiobutton-emsiotify .ripple
  {
     overflow:visible;
 }
 .jqx-checkbox-emsiotify .ink,
 .jqx-radiobutton-emsiotify .ink 
  {
     transform: scale(0); 
     background: var(--jqx-accent-color-emsiotify);
     border-radius: 50%;
 }
 .jqx-checkbox-emsiotify .ink.animate,
 .jqx-radiobutton-emsiotify .ink.animate
  {
     animation: checkRipple 0.3s ease;
     animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
 }
 .jqx-checkbox-emsiotify .ink.active,
 .jqx-radiobutton-emsiotify .ink.active
  {
     opacity: 0.2;
     transform: scale(2);
 }
 .jqx-checkbox-default-emsiotify.active .ink,
 .jqx-radiobutton-default-emsiotify.active .ink
  {
     opacity: 0.2;
     transform: scale(2);
 }
 /* Ripple effect */
 .buttonRipple {
   background-position: center;
   transition: background 0.8s;
 }
 .jqx-widget-emsiotify.buttonRipple:hover {
   background: var(--jqx-button-default-hovered-state-background-color-emsiotify) radial-gradient(circle, transparent 1%, var(--jqx-button-default-hovered-state-background-color-emsiotify)  1%) center/15000%;
   border-color: var(--jqx-button-default-hovered-state-background-color-emsiotify);
 }
 .jqx-widget-emsiotify.buttonRipple:active {
   background-color: var(--jqx-button-default-ripple-color-emsiotify);
   background-size: 100%;
   transition: background 0s;
 }
 .jqx-widget-emsiotify.buttonRipple:active:not(:hover) {
       color: var(--jqx-background-color-emsiotify);
 }
 @keyframes ripple {
     100% {
         opacity: 0;
         transform: scale(5);
         animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
     }
 }
 @keyframes checkRipple {
     100% {
         opacity: 0.2;
         transform: scale(2);
         animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
     }
 }
 
 .jqx-fill-state-pressed-emsiotify .jqx-icon-delete-emsiotify
 {
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/icon-delete.png');
 }
 .jqx-fill-state-pressed-emsiotify .jqx-icon-edit-emsiotify
 {
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/icon-edit.png');
 }
 .jqx-fill-state-pressed-emsiotify .jqx-icon-save-emsiotify
 {
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/icon-save.png');
 }
 .jqx-fill-state-pressed-emsiotify .jqx-icon-cancel-emsiotify
 {
     background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/icon-cancel.png');
 }
 .jqx-fill-state-pressed-emsiotify .jqx-icon-search-emsiotify
 {
     background-image: url(../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/search_white.png);
 }
 .jqx-fill-state-pressed-emsiotify .jqx-icon-plus-emsiotify
 {
     background-image: url(../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/plus_white.png);
 }
 .jqx-fill-state-pressed-emsiotify .jqx-menu-minimized-button-emsiotify {
    background-image: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/icon-menu-minimized.png');
 }
 .jqx-fill-state-hover-emsiotify .jqx-editor-toolbar-icon-emsiotify,
 .jqx-fill-state-pressed-emsiotify .jqx-editor-toolbar-icon-emsiotify {
     background: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/html_editor_white.png') no-repeat;
 }
 
 .jqx-fill-state-hover-emsiotify .jqx-editor-toolbar-icon-fontsize-emsiotify,
 .jqx-fill-state-pressed-emsiotify .jqx-editor-toolbar-icon-fontsize-emsiotify,
 .jqx-fill-state-hover-emsiotify .jqx-editor-toolbar-icon-forecolor-emsiotify,
 .jqx-fill-state-pressed-emsiotify .jqx-editor-toolbar-icon-forecolor-emsiotify
 {
         background: url('../../../node_modules/jqwidgets-ng/jqwidgets/styles/images/html_editor.png') no-repeat;
 }
 
 .jqx-editor-toolbar-button-emsiotify{
     border-color: #ddd;
     box-shadow: none !important;
     color: #333;
 }
 
 .jqx-widget-emsiotify #formWrap {
     color: #555 !important;    
 }
 
 .jqx-grid-cell-pinned-emsiotify{
     background-color:#e0e9f5 !important;
 }
 /* .jqx-widget-content{
     background: transparent !important;
     color: #fff;
 } */
 
